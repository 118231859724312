export default [
  {
    q: '这个教程适合我吗？',
    a: '本教程适合于零基础希望入门Python编程，以及有一些基础希望彻底学会Python编程的同学，非理工科的同学以及职场人也适合学习；尤其适合希望提高学习效率并彻底学会的同学。'
  },
  {
    q: '中学生可以学会吗？',
    a: '可以的，初中生和高中生都可以学会；但初中生在学习进阶模块和项目实战模块可能会遇到一些挑战，建议这时及时向 ChatGPT 和助教寻求帮助。'
  },
  {
    q: '零基础可以学会吗？',
    a: '可以学会，但零基础的同学在学习的过程中可能会遇到更多的挑战和问题，建议遇到问题时及时向ChatGPT询问，或者向助教咨询。'
  },
  {
    q: '学完这个教程可以掌握到什么程度？',
    a: '（1）掌握几乎全部的Python编程知识，包括进阶部分的函数编程和面向对象编程知识；（2）入门数据分析、数据可视化以及网络爬虫应用开发；（3）能够上手Python项目开发。'
  },
  {
    q: '多久可以学完这个教程？',
    a: '对于零基础的同学，每节课程需要30-45分钟；对于有一定编程基础的同学，每节课程需要15-30分钟。'
  },
  {
    q: '这个教程免费吗？',
    a: '注册成功后，系统会赠送新用户免费的ChatGPT调用次数，可以解锁部分章节的课程。当ChatGPT调用次数用完以后，如果用户继续学习，需要购买会员。'
  },
  {
    q: '三种不同的会员有什么区别？',
    a: '不同的会员对应的课程内容以及ChatGPT调用次数不同，详细信息请见“购买会员”页面。'
  },
  {
    q: 'ChatGPT调用次数用完以后怎么办？',
    a: '三种不同的会员对应的ChatGPT调用次数应该可以满足大家学习相应课程的需求；如果调用次数用完但课程还没有学完，可以选择续费或者联系客服人员。'
  },
  {
    q: '学习过程中有没有助教答疑？',
    a: '针对购买299元的会员用户，管理员会建立VIP社群，助教会在VIP社群回答大家的疑问。'
  },
  {
    q: '有学习群吗？',
    a: '有的，请添加客服微信，暗号：学习群。'
  },
  {
    q: '我还有其他问题怎么办？',
    a: '请添加客服微信，直接给TA留言吧。'
  }
]
