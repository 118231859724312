<template>
  <div class="congratulation-box animate__animated animate__bounceInDown">
    <div class="left">
      <h1>Congratulations！</h1>
      <p class="text">您已完成引导学习！</p>
      <p class="share-text font-14">你愿意把本教程分享给你的朋友吗？添加微信</p>
      <el-button type="primary" @click="jumpToNext">开始学习</el-button>
    </div>
    <img src="@/assets/img/congratulations-bg.png" alt="">
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  name: 'guideSuccess',
  methods: {
    ...mapMutations(['SET_OPEN_MASK']),
    jumpToNext() {
      this.SET_OPEN_MASK(false)
      this.$router.push('/python/lessons/1')
    }
  }
}
</script>
<style scoped lang="scss">
.congratulation-box {
  margin-top: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 400px;
  background: #F5F7FA;
  h1 {
    font-size: 56px;
    margin: 0;
  }
  .text {
    margin-bottom: 20px;
    color: #835AFD;
    font-size: 22px;
  }
  .share-text {
    color: #A8A8B3;
    font-size: 16px;
    margin-bottom: 20px;
  }
  img {
    width: 276px;
    height: 276px;
  }
}
</style>
