import service from '@/utils/request'
import { EncryptData } from '@/utils'

// 获取课程列表
export const courseListApi = () => service.post('/api/course/list')

// 根据用户id获取课程列表
export const courseListByCustomerIdApi = userId => service.get(`/api/course/listByCustomerId?customerId=${userId}`)

// 设置课程记录
export const addCourseRecodeApi = data => service.post('/api/record/complete', EncryptData(JSON.stringify(data)), {
  headers: {
    'Content-Type': 'application/json'
  }
})

// 获取课程记录
export const getCourseRecodeApi = userId => service.get(`/api/record/list?customerId=${userId}`)

// 查询用户作业列表
export const getHomeWorkListApi = (userId, courseId) => service.get(`/api/homework/list?customerId=${userId}&courseSubdirectoryId=${courseId}`)

// 添加用户作业记录
export const addHomeWorkApi = data => service.post('/api/homework/add', data)

// 编辑用户作业记录
export const editHomeWorkApi = data => service.post('/api/homework/edit', data)
