<template>
  <div class="course-study">
    <div class="course-study-container hidden-xs-only">
      <!-- 头部 -->
      <div class="study-header">
        <!-- <div class="logo"></div> -->
        <div class="right">
          <div class="title">跟ChatGPT学Python，一次性学会！</div>
          <!-- <div class="author">作者: 张宝 道可道智能创始人</div> -->
          <div class="learning">
            <span class="color-primary mr-5">{{ learningCount }}</span>
            <span>位同学正在学习</span>
          </div>
          <div class="percent-box">
            <div class="desc">
              <span>共 <span class="color-primary">{{ totalNum }}</span> 节 | </span>
              <span>&nbsp;&nbsp;你已通过: <span class="color-primary"> {{ passNum }} </span>节</span>
            </div>
            <div class="btn" @click="toStart">开始学习</div>
          </div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="course-menu-content">
        <course-menu :course-data="courseList" />
        <div class="author-info">
          <div class="header">教程作者:</div>
          <!-- <div class="info">
            <div class="avtar">
              <img src="@/assets/img/author.png" alt="">
            </div>
            <div class="user">
              <p>张宝</p>
              <p>道可道智能创始人</p>
            </div>
          </div> -->
          <div class="desc">
            张宝，道可道智能科技创始人,IT职业教育品牌“良师良职”创始人，毕业于中国科学技术大学，15年IT行业从业经验，曾担任平安
            及腾讯子公司产品总监以及某商业银行数据仓库负责人。
          </div>
          <div class="author-img">
            <img src="@/assets/img/author.jpeg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="course-study-container hidden-sm-and-up">
      <!-- 头部 -->
      <div class="study-header">
        <div class="logo"></div>
        <div class="right">
          <div class="title">跟ChatGPT学Python，一次性学会！</div>
          <div class="author">作者: 张宝,道可道智能创始人</div>
          <div class="percent-box">
            <div class="desc">
              <span>共 <span class="color-primary">{{ totalNum }}</span> 节 | </span>
              <span>&nbsp;&nbsp;你已通过: <span class="color-primary"> {{ passNum }} </span>节</span>
            </div>
            <div class="btn" @click="toTips">开始学习</div>
          </div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="course-menu-content">
        <course-menu :course-data="courseList" />
      </div>
    </div>
  </div>
</template>
<script>
import CourseMenu from './components/CourseMenu'
import { getItem, setItem } from '@/utils/storage'
// import courseJSON from '@/constant/course.json'
import { courseListByCustomerIdApi } from '@/api/course'
import { mapGetters } from 'vuex'
export default {
  name: 'course-study',
  computed: {
    ...mapGetters(['userId', 'userInfo', 'learningCount']),
    totalNum() {
      let total = 0
      this.courseList.forEach(item => {
        item.courseWithLearningStatusList.forEach(sub => {
          total += 1
        })
      })
      return total > 0 ? total - 2 : 100
    },
    passNum() {
      let total = 0
      this.courseList.forEach(item => {
        item.courseWithLearningStatusList.forEach(sub => {
          if (sub.status === '1') total += 1
        })
      })
      return total
    }
  },
  components: {
    CourseMenu
  },
  data() {
    return {
      activeName: getItem('CourseStudyActiveName') || 'first',
      courseList: []
    }
  },
  watch: {
    activeName(val) {
      setItem(val, 'CourseStudyActiveName')
    }
  },
  created() {
    this.getCourseList()
  },
  mounted() {
    this.$store.dispatch('listenScroll', '.course-study')
  },
  methods: {
    async getCourseList() {
      const { data } = await courseListByCustomerIdApi(this.userId)
      this.courseList = data
    },
    toTips() {
      this.$notify({
        title: '温馨提示',
        message: '为了获得最佳学习效果，请您在电脑端浏览器中打开本网页，网页地址：www.studywithgpt.net',
        duration: 0
      })
    },
    toStart() {
      const { isMember, endTime } = this.userInfo
      if (!isMember || this.checkIsExpire(endTime)) return this.$router.push('/payment')
      this.courseList.forEach(item => {
        item.courseWithLearningStatusList.forEach(sub => {
          if (sub.status === '0') {
            this.$router.push(`/python/lessons/${sub.id - 1}`)
          }
        })
      })
    },
    checkIsExpire(endTime) {
      return new Date(endTime).getTime() < new Date().getTime()
    }
  },
  beforeDestroy() {
    this.$store.dispatch('removeScroll')
  }
}
</script>
<style lang="scss">
.course-study {
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 108px);
  overflow-y: auto;
  padding-top: 60px;
  background: linear-gradient(to bottom, #e7e1fd 0%, #fff 50%, #fff 100%);

  .course-study-container {
    width: 80vw;
    padding-top: 40px;
    .study-header {
      width: 100%;
      display: flex;
      margin-bottom: 110px;
      .logo {
        width: 96px;
        height: 96px;
        margin-right: 25px;
        // background-color: aqua;
      }
      .right {
        .title {
          font-size: 50px;
          font-weight: 600;
          margin-bottom: 16px;
          color: #18191C;
          margin-top: 30px;
        }
        .author {
          font-size: 25px;
          font-weight: 500;
          color: #666;
        }
        .learning {
          font-size: 24px;
          font-weight: bold;
          color: #666;
          margin-top: 100px;
          display: flex;
          align-items: center;
        }
        .color-primary {
          color: #ea6560 !important;
        }
        .mr-5 {
          margin-right: 5px;
        }
        .percent-box {
          display: flex;
          width: 520px;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0 30px;
          .desc {
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: bold;
            span {
              white-space: nowrap;
              color: #666;
            }
          }
          .btn {
            width: 130px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ea6560;
            border-radius: 6px;
            color: #fff;
            cursor: pointer;
            font-size: 20px;
          }
          .btn:hover {
            opacity: 0.8;
          }
        }
      }
    }
    #readme {
      padding-bottom: 20px;
      .markdown-body {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
    }
    .course-menu-content {
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      .author-info {
        position: relative;
        top: 10px;
        width: 500px;
        .header {
          font-size: 25px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .info {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          margin-top: 10px;
          .avtar {
            display: flex;
            align-items: center;
            img {
              width: 70px;
              height: 70px;
            }
            margin-right: 20px;
          }
          .user {
            p:nth-child(1) {
              font-size: 22px;
            }
            p:nth-child(2) {
              color: #999;
            }
          }
        }
        .desc {
          line-height: 30px;
          letter-spacing: 1px;
        }
        .author-img {
          position: absolute;
          top: -380px;
          left: 0;
          img {
            width: 330px;
            height: 330px;
          }
        }
      }
    }
  }
  .course-study-container.hidden-sm-and-up {
    width: 100vw;
    padding-top: 40px;
    .study-header {
      width: 100%;
      display: flex;
      margin-bottom: 30px;
      .logo {
        width: 96px;
        height: 96px;
        margin-right: 25px;
        // background-color: aqua;
      }
      .right {
        .title {
          font-size: 90px;
          font-weight: 600;
          margin-bottom: 16px;
          color: #18191C;
          margin-top: 30px;
        }
        .percent-box {
          display: flex;
          width: 1200px;
          justify-content: space-between;
          align-items: center;
          margin: 80px 0;
          .desc {
            display: flex;
            align-items: center;
            font-size: 60px;
            font-weight: bold;
            span {
              white-space: nowrap;
            }
            .color-primary {
              color: #835AFD;
            }
          }
          .btn {
            width: 300px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #835AFD;
            border-radius: 6px;
            color: #fff;
            cursor: pointer;
            font-size: 50px;
          }
          .btn:hover {
            opacity: 0.8;
          }
        }
      }
    }
    #readme {
      padding-bottom: 20px;
      .markdown-body {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
    }
    .course-menu-content {
      padding: 0 120px;
      width: 100%;
      box-sizing: border-box;
      .course-menu {
        margin-right: 0;
      }
    }
  }
}
</style>
