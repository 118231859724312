<template>
  <div class="wechat-qrcode">
    <span class="header">扫描支付</span>
    <span class="tips">暂时只支持「微信」扫码</span>
    <div id="qrcode">
      <img v-if="wxpayURLImage" :src="wxpayURLImage" alt="">
    </div>
    <div class="wxpay-logo">
      <i class="iconfont icon-wxpay"></i>
      微信支付
    </div>
    <i class="iconfont icon-close" @click="closeQrcode"></i>
  </div>
</template>
<script>
import QRCode from 'qrcode'
import { mapGetters } from 'vuex'
export default {
  name: 'wechat-qrcode',
  computed: {
    ...mapGetters(['wxpayURL'])
  },
  watch: {
    wxpayURL(val) {
      if (val) {
        this.initQrcode()
      }
    }
  },
  data() {
    return {
      wxpayURLImage: ''
    }
  },
  methods: {
    async initQrcode() {
      this.wxpayURLImage = await QRCode.toDataURL(this.wxpayURL)
    },
    closeQrcode() {
      this.$store.commit('SET_OPEN_MASK', false)
      this.$store.commit('SET_WXPAY_URL', '')
    }
  }
}
</script>
<style scoped lang="scss">
.wechat-qrcode {
  position: absolute;
  width: 400px;
  height: 400px;
  background: #FFFFFF;
  border-radius: 6px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    font-size: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #353535;
    line-height: 40px;
  }
  .tips {
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #4D4D4D;
  }
  #qrcode {
    width: 200px;
    height: 200px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 2px solid #A8A8B3;
    margin: 15px 0 20px 0;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .wxpay-logo {
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #4D4D4D;
    i {
      font-size: 24px;
    }
  }
  .icon-close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
