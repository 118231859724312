<template>
  <div class="guide-page" :class="{ guideFlag }">
    <congratulation v-if="maskActions ==='congratulation'" />
    <guideSuccess v-if="maskActions ==='guideSuccess'" />
    <wechatQrcode v-if="maskActions ==='wxpay'" />
    <step1 v-if="maskActions ==='step1'" />
    <step2 v-if="maskActions ==='step2'" />
    <step3 v-if="maskActions ==='step3'" />
    <step4 v-if="maskActions ==='step4'" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import congratulation from './components/congratulation'
import guideSuccess from './components/guideSuccess'
import wechatQrcode from './components/wechat'
import step1 from './components/step1'
import step2 from './components/step2'
import step3 from './components/step3'
import step4 from './components/step4'
export default {
  name: 'guide-page',
  components: {
    congratulation,
    guideSuccess,
    step1,
    step2,
    step3,
    step4,
    wechatQrcode
  },
  computed: {
    ...mapState(['guideFlag']),
    ...mapGetters(['maskActions'])
  },
  mounted() {
    if (this.maskActions !== 'wxpay') {
      document.documentElement.style.overflow = 'hidden'
    }
  },
  beforeDestroy() {
    if (this.maskActions !== 'wxpay') {
      document.documentElement.style.overflow = 'auto'
    }
  }
}
</script>
<style scoped lang="scss">
.guide-page {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgb(0, 0, 0, .6);
  z-index: 999;
}
.guide-page.guideFlag {
  background: rgba($color: #fff, $alpha: .5);
}
</style>
