import Vue from 'vue'
import Vuex from 'vuex'
import EventBus from '@/components/course/EventBus'
import { getItem, setItem } from '@/utils/storage'
import { userInfoApi, getUserCountApi } from '@/api/login'
import { Notification } from 'element-ui'
import router from '@/router'

Vue.use(Vuex)

let store = null
let scrollDOM = null
const scrollCB = () => {
  if (!scrollDOM) return
  const scrollTOP = scrollDOM.scrollTop || scrollDOM.pageYOffset
  store.commit('SET_SCROLL_TOP', scrollTOP)
}

store = new Vuex.Store({
  state: {
    user: getItem() || {},
    openMask: false,
    guideFlag: false,
    maskActions: '', // congratulation: 题目正确提示 wxpay: 微信支付
    courseInfo: {},
    wxpayURL: '',
    hasCheckRoute: true,
    wxLoginInfo: {},
    userInfoTimer: null,
    scrollTop: 0,
    learningCount: 0
  },
  getters: {
    token: state => state.user.token,
    openMask: state => state.openMask,
    maskActions: state => state.maskActions,
    userId: state => state.user.userId,
    nickName: state => state.user.nickName,
    wxpayURL: state => state.wxpayURL,
    inviterUrl: state => {
      const url = location.href.split('/#/')[0]
      return `我发现了一个利用ChatGPT自学Python编程的宝藏网站。网站包含了GPT-4和真人双助教，可以实时互动答疑；不但学会编程，还可以学会AI；100节课从零基础到项目实战；无需安装编程软件，一个网页就能开始学习。邀请你一起体验。通过我的专属链接（${url}/#/home?icode=${state.user.inviterCode}）注册，还可以免费体验ChatGPT和部分课程。快来一起加入吧~`
    },
    userInfo: state => state.user,
    isMember: state => state.user.isMember,
    ticket: state => state.wxLoginInfo.ticket,
    scrollTop: state => state.scrollTop,
    wxURL: state => state.wxLoginInfo.url,
    learningCount: state => state.learningCount
  },
  mutations: {
    SET_LEARNING_COUNT(state, payload) {
      state.learningCount = payload
    },
    SET_WX_LOGIN_INFO(state, payload) {
      state.wxLoginInfo = payload
    },
    SET_WXPAY_URL(state, payload) {
      state.wxpayURL = payload
    },
    SET_COURSE_INFO(state, payload) {
      state.courseInfo = payload
    },
    SET_CHECK_ROUTE(state, payload) {
      state.hasCheckRoute = payload
    },
    SET_GUIDE_FLAG(state, payload) {
      state.guideFlag = payload
    },
    SET_USER(state, payload) {
      state.user = payload
      setItem(payload)
    },
    SET_OPEN_MASK(state, payload) {
      state.openMask = payload
    },
    SET_MASK_ACTIONS(state, payload) {
      state.maskActions = payload
    },
    SET_SCROLL_TOP(state, payload) {
      state.scrollTop = payload
    }
  },
  actions: {
    async getUserCount({ commit }, payload) {
      const { data } = await getUserCountApi()
      commit('SET_LEARNING_COUNT', data)
    },
    async userInfoAction({ state, commit, dispatch }, checkMember = false) {
      const { data } = await userInfoApi()
      if (checkMember && data.gptBalanceCount < 1) {
        setItem(0, 'paytips')
        dispatch('goPaymentAction', '亲爱的用户，您和ChatGPT的精彩对话已到达上限啦！我们诚邀您升级会员，继续享受与ChatGPT一起探索和学习的乐趣~')
      }
      commit('SET_USER', { ...state.user, ...data })
    },
    goPaymentAction(context, message) {
      console.log(context)
      const paytips = getItem('paytips')
      if (paytips === 1) {
        Notification.closeAll()
        return
      }
      setItem(1, 'paytips')
      EventBus.$emit('payment-tips', message)
    },
    async getWxLogin({ state, commit, dispatch }) {
      router.push('/login')
      // const { data } = await getWxQrCodeApi()
      // commit('SET_WX_LOGIN_INFO', data)
      // clearInterval(state.userInfoTimer)
      // state.userInfoTimer = setInterval(async () => {
      //   const res = await getUserInfoByWxApi(data.ticket)
      //   if (res.data) {
      //     Message.success('登录成功')
      //     dispatch('getUserCount')
      //     commit('SET_USER', res.data)
      //     dispatch('userInfoAction')
      //     commit('SET_WX_LOGIN_INFO', {})
      //     clearInterval(state.userInfoTimer)
      //     router.push('/study')
      //   }
      // }, 1000)
    },
    listenScroll({ commit }, container) {
      commit('SET_SCROLL_TOP', 0)
      scrollDOM = container ? document.querySelector(container) : window
      scrollDOM.addEventListener('scroll', scrollCB)
    },
    removeScroll({ commit }) {
      commit('SET_SCROLL_TOP', 0)
      if (!scrollDOM) return
      scrollDOM.removeEventListener('scroll', scrollCB)
      scrollDOM = null
    }
  }
})

export default store
