<template>
  <div id="comment" class="comment">
    <!-- <div class="header">讨论区Q&A</div> -->
    <div class="comment-box">
      <!-- <div class="user">
        <img src="@/assets/img/avatar.png" alt="">
        <span class="username">{{ nickName }}</span>
      </div> -->
      <div class="center">
        <el-input
          type="textarea"
          placeholder="欢迎留言（ Ctrl + Enter 发表 )"
          v-model="userComment"
          maxlength="2000"
          :rows="5"
          show-word-limit/>
      </div>
      <div class="bottom">
        <el-button :disabled="userComment.length == 0" type="primary" size="small" @click="addComment">提交留言</el-button>
      </div>
    </div>
    <div class="comment-area">
      <div class="tips-box" v-if="commentData.length > 0">全部留言（{{ commentData.length }})</div>
      <div class="comment-container">
        <div class="comment-item" v-for="item in commentData" :key="item.id">
          <div class="user">
            <img src="@/assets/img/avatar.png" alt="">
            <span class="username">{{ item.userNickname }}</span>
          </div>
          <div class="content">{{ item.content }}</div>
          <div class="comment-reply">
            <el-tooltip class="item" effect="dark" content="回复" placement="top-end">
              <i class="iconfont icon-comment" @click="replyClick(item)"></i>
            </el-tooltip>
          </div>
          <div v-if="item.isEdit" class="reply-input">
            <el-input v-model="replyComment" v-focus :placeholder="`回复${item.userNickname}`" size="mini"  type="textarea" :autosize="{ minRows: 2, maxRows: 4}" resize="none"></el-input>
            <el-button :disabled="replyComment.length === 0" type="primary" size="mini" @click="reply(item)">回复</el-button>
          </div>
          <div class="reply-list">
            <div v-for="reply in item.replies" :key="reply.id" class="reply-item">
              <span class="reply-username">{{ reply.userNickname }}回复:</span>
              <span>{{ reply.content }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addCommentApi, commentListApi } from '@/api/comment'
import { mapState } from 'vuex'
export default {
  name: 'comment',
  computed: {
    ...mapState(['user']),
    nickName() {
      return this.user.nickName
    },
    courseId() {
      const { lessons } = this.$route.params
      return Number(lessons) + 1
    }
  },
  data() {
    return {
      userComment: '',
      replyComment: '',
      replyFlag: false,
      commentData: []
    }
  },
  directives: {
    // 自动聚焦
    focus: {
      inserted(el) {
        el.querySelector('textarea').focus()
      }
    }
  },
  created() {
    this.getCommentList()
  },
  methods: {
    // 添加评论
    async addComment() {
      const { userId } = this.user
      const params = {
        content: this.handlerComment(this.userComment),
        courseSubdirectoryId: this.courseId,
        customerId: userId,
        parentId: 0,
        userAvatar: '',
        userNickname: this.nickName
      }
      this.commentData.unshift({ ...params, isEdit: false })
      await addCommentApi(params)
      this.userComment = ''
    },
    // 点击回复按钮
    async replyClick(item) {
      this.commentData.forEach(item => {
        item.isEdit = false
      })
      item.isEdit = !item.isEdit
    },
    // 回复评论
    async reply(item) {
      const { userId } = this.user
      const params = {
        content: this.handlerComment(this.replyComment),
        courseSubdirectoryId: this.courseId,
        customerId: userId,
        parentId: item.id,
        userAvatar: '',
        userNickname: this.nickName
      }
      item.replies.unshift(params)
      await addCommentApi(params)
      this.replyComment = ''
    },
    // 过滤特殊字符防止XSS攻击
    handlerComment(comment) {
      return comment.replace(/[\x00-\x1F\x7F"|'<>%#$%^&*()~|?]+/g, '')
    },
    // 获取评论列表
    async getCommentList() {
      const { data } = await commentListApi(this.courseId)
      this.commentData = data.map(item => ({
        isEdit: false,
        ...item
      }))
    }
  }
}
</script>
<style scoped lang="scss">
.comment {
  padding: 80px 0;
  .header {
    font-size: 25px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #18191C;
    margin-bottom: 20px;
  }
  .comment-box {
    width: 100%;
  }
  .user {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      width: 32px;
      height: 32px;
      margin-right: 5px;
    }
    .username {
      font-size: 20px;
    }
  }

  .center {
    width: 100%;
    .el-input {
      border-radius: 8px;
      border: 1px solid #835AFD;
    }
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .comment-area {
    width: 100%;
    margin-top: 20px;
    .tips-box {
      width: 108px;
      height: 32px;
      // background: #E559F9;
      // border-radius: 16px;
      // color: #fff;
      text-align: center;
      line-height: 32px;
      margin-bottom: 20px;
      font-size: 18px;
    }
    .comment-container {
      width: 100%;
      .comment-item {
        width: 100%;
        padding: 20px;
        // background:#f8f8f8;
        // border-radius: 8px;
        box-sizing: border-box;
        margin-bottom: 30px;
        border-bottom: 1px solid #efefef;
        .content {
          font-size: 20px;
          line-height: 30px;
          color: #29292E;
          margin-bottom: 20px;
        }
        .comment-reply {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .icon-comment {
            color: #737380;
            cursor: pointer;
            font-size: 20px;
            &:hover {
              color: #835AFD;
            }
          }
          .replyComment:hover {
            color: #835AFD;
          }
        }
        .reply-input {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 10px;
          .el-input {
            flex: 1;
            // height: 40px;
          }
          .el-button {
            margin-left: 20px;
            height: 34px;
            font-size: 18px;
          }
        }
        .reply-list {
          width: 100%;
          margin-top: 10px;
          .reply-item {
            width: 100%;
            padding: 30px;
            box-sizing: border-box;
            background: #f6f7fb;
            border-radius: 4px;
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 30px;
            .reply-username {
              font-weight: 500;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
