<template>
  <el-dialog
    class="pay-tips-dialog"
    :visible.sync="dialogVisible"
    top="30vh"
    width="30%"
    :close-on-click-modal="false"
    @close="handlerClose"
    >
    <div class="content">
      <div class="header">温馨提示</div>
      <div class="desc">{{ tipsText }}</div>
      <div class="btn" @click="jumpPayment">立即续费</div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'payment-tips',
  data() {
    return {
      dialogVisible: false,
      countdown: 0,
      countdownText: '立即续费(5s)',
      timer: null,
      tipsText: ''
    }
  },
  methods: {
    openCountDown() {
      // 模拟发送验证码成功后开始倒计时
      this.countdown = 5
      this.timer = setInterval(() => {
        this.countdown--
        this.countdownText = `立即续费(${this.countdown}s)`
        if (this.countdown === 0) {
          this.jumpPayment()
        }
      }, 1000)
    },
    jumpPayment() {
      clearInterval(this.timer)
      this.timer = null
      this.dialogVisible = false
      this.$router.push('/payment')
    },
    openDialog(msg) {
      this.dialogVisible = true
      this.tipsText = msg
      // this.openCountDown()
    },
    handlerClose() {
      this.countdownText = '立即续费(5s)'
      clearInterval(this.timer)
      this.timer = null
    }
  }
}
</script>
<style scoped lang="scss">
.pay-tips-dialog {
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header,.desc,.btn {
      display: inline-block;
    }
    .header {
      font-size: 30px;
      color: #353535;
      font-weight: 500;
    }
    .desc {
      font-size: 16px;
      line-height: 35px;
      margin: 40px 0 70px 0;
    }
    .btn {
      width: 470px;
      height: 50px;
      background: #835AFD;
      border-radius: 2px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
    }
    .btn:hover {
      opacity: 0.8;
    }
  }
}
</style>
