<template>
  <div id="App">
    <router-view></router-view>
    <pay-tips ref="paytips" />
    <login-dialog ref="loginDialog" />
  </div>
</template>
<script>
import payTips from '@/components/payTips'
import loginDialog from '@/components/loginDialog'
import EventBus from './components/course/EventBus'
import { mapGetters } from 'vuex'
import { isMobile } from '@/utils'
export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'token',
      'isMember',
      'ticket'
    ])
  },
  watch: {
    isMember(val) {
      if (val) {
        clearTimeout(window.payTimer)
      }
    },
    ticket(val) {
      if (val) {
        this.$refs.loginDialog.open(val)
      } else {
        this.$refs.loginDialog.close()
      }
    }
  },
  components: {
    payTips,
    loginDialog
  },
  created() {
    // const sflag = sessionStorage.getItem('newsession')
    this.$store.dispatch('getUserCount')
    EventBus.$on('payment-tips', msg => {
      if (isMobile()) return
      this.$refs.paytips.openDialog(msg)
    })
    if (this.token) {
      this.$store.dispatch('userInfoAction')
      // if (!sflag && !this.isMember) {
      //   clearTimeout(window.payTimer)
      //   if (isMobile()) return
      //   window.payTimer = setTimeout(() => {
      //     clearTimeout(window.payTimer)
      //     this.$refs.paytips.openDialog('亲爱的用户，感觉不错的话请升级会员吧')
      //   }, 60000)
      // }
      // sessionStorage.setItem('newsession', '1')
    }
  },
  beforeDestroy() {
    EventBus.$off('payment-tips')
  }
}
</script>
<style scoped lang="scss">
@import url(./styles/common.scss);
#App {
  overflow-x: hidden;
}
</style>
