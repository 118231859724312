import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import Study from '@/views/python/study'
import Lessons from '@/views/python'
import QA from '@/views/qa'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home')
      },
      {
        path: 'payment',
        component: () => import('@/views/payment')
      },
      {
        path: '/study',
        component: Study
      },
      {
        path: '/qa',
        component: QA
      },
      {
        path: 'python/lessons/:lessons',
        component: Lessons
      },
      {
        path: 'python/test/:test',
        component: () => import('@/views/python')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login')
  }
]

const router = new VueRouter({
  routes
})

export default router
