<template>
  <div id="mdContainer">
    <el-skeleton v-if="loadingContent" :rows="15" animated />
    <div ref="mdContainer"></div>
  </div>
</template>
<script>
import * as monaco from 'monaco-editor'
// import courseJSON from '@/constant/course.json'
import EventBus from './EventBus'
import { getItem, setItem } from '@/utils/storage'
import { mapState, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { getHomeWorkListApi, addHomeWorkApi, editHomeWorkApi } from '@/api/course'
export default {
  name: 'RenderContent',
  data () {
    return {
      editors: [],
      theme: 'vs-dark',
      editorOptions: {
        language: 'python',
        minimap: { enabled: false },
        automaticLayout: true, // 是否开启自动布局
        theme: '', // 官方自带三种主题vs 白色, hc-black 黑色, or vs-dark 非常黑
        fontSize: 14,
        value: '',
        scrollBeyondLastLine: false, // 禁止滚动到最后一行之后
        smoothScrolling: false, // 禁止平滑滚动
        readOnly: true,
        pyodide: null,
        reformat_exception: null,
        // 设置默认行数为10行
        readOnlyOptions: {
          lineNumbers: {
            value: 10,
            cssClass: 'line-numbers'
          }
        }
      },
      codeResultStr: '',
      codeResultArr: [],
      userQuestions: [],
      codeError: false,
      loadingContent: false,
      homeworkList: [] // 作业数据
    }
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapState(['guideFlag'])
  },
  watch: {
    '$route'() {
      this.loadPage()
    }
  },
  async created() {
    monaco.editor.defineTheme(this.theme, {
      base: this.theme, // can also be vs-dark or hc-black
      inherit: true, // can also be false to completely replace the builtin rules
      rules: [
        // you can add your rules here
      ],
      colors: {
        'scrollbarSlider.background': '#1f1e1e', // change this to your desired color
        'scrollbarSlider.hoverBackground': '#1f1e1e', // change this to your desired color
        'scrollbarSlider.activeBackground': '#1f1e1e' // change this to your desired color
      }
    })

    monaco.languages.registerCompletionItemProvider('python', {
      provideCompletionItems: function (model, position) {
        // 获取当前行数
        const line = position.lineNumber

        // 获取当前列数
        const column = position.column
        // 获取当前输入行的所有内容
        const content = model.getLineContent(line)
        // 通过下标来获取当前光标后一个内容，即为刚输入的内容
        const sym = content[column - 2]
        const word = model.getWordUntilPosition(position)
        const range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn
        }
        const suggestions = []
        if (sym === '$') {
          // ...
          // 拦截到用户输入$，开始设置提示内容，同else中代码一致，自行拓展
        } else {
          // 直接提示，以下为sql语句关键词提示
          const sqlStr = [
            'print()'
          ]
          for (const i in sqlStr) {
            suggestions.push({
              label: sqlStr[i], // 显示的提示内容
              kind: monaco.languages.CompletionItemKind.Function, // 用来显示提示内容后的不同的图标
              insertText: sqlStr[i], // 选择后粘贴到编辑器中的文字
              detail: '', // 提示内容后的说明
              range: range
            })
          }
        }
        return {
          suggestions: suggestions
        }
      },
      triggerCharacters: ['$', '']
    })
    this.initPyodide()
  },
  mounted() {
    this.loadPage()
  },
  methods: {
    // 初始化python代码解释器
    async initPyodide() {
      // eslint-disable-next-line
      this.pyodide = await loadPyodide({
        stdout: msg => {
          this.userQuestions.forEach(item => {
            if (msg.indexOf(item) > -1) {
              msg = msg.replace(item, '')
            }
          })
          this.userQuestions = []
          this.codeResultArr.push(msg)
          this.codeResultStr = this.codeResultArr.join('\r\n')
        }
      })

      this.pyodide.runPython(`
        import sys
        def reformat_exception():
            from traceback import format_exception
            # Format a modified exception here
            # this just prints it normally but you could for instance filter some frames
            return "".join(
                format_exception(sys.last_type, sys.last_value, sys.last_traceback)
            )
      `)

      this.reformat_exception = this.pyodide.globals.get('reformat_exception')
    },
    // 加载页面
    async loadPage (theme = 'vs-dark') {
      this.getHomeworkList()
      this.editors = []
      const { lessons, test } = this.$route.params
      const curJsonI = lessons || test
      let str = ''
      this.loadingContent = true
      const courseJSON = await import(`@/constant/lessons-${curJsonI}.json`)
      this.loadingContent = false
      if (lessons) {
        str = courseJSON[`lessons-${lessons}`]
      } else {
        str = courseJSON[`test-${test}`]
      }
      this.$refs.mdContainer.innerHTML = str
      await this.$nextTick()
      // 获取文章菜单
      this.getSubMenu()
      const staticCode = Array.from(document.querySelectorAll('.fenced-code-block')).filter(el => el.className.indexOf('language-') === -1)
      const tmp1 = document.querySelectorAll('.language-tex')
      const tmp2 = document.querySelectorAll('.language-html')
      const tmp3 = document.querySelectorAll('.language-str')
      const codeTpms = [...tmp1, ...tmp2, ...tmp3]
      // 插入运行和AI释义按钮
      codeTpms.forEach((el, index) => {
        const fragment = document.createDocumentFragment()
        const div = document.createElement('div')
        div.className = `code-oper-box ${theme}`
        div.innerHTML = `
          <div class="btns">
            <div class="btn-item runCode">运行</div>
            <div class="btn-item aiInterpretation">${lessons ? '解释' : '提交'}</div>
          </div>
        `
        div.setAttribute('data-index', index)
        fragment.appendChild(div)
        el.parentElement.prepend(fragment)
      })
      document.querySelectorAll('.code-oper-box').forEach(el => {
        if (el.parentElement) {
          el.parentElement.style.background = '#fff'
          el.parentElement.style.overflow = 'hidden'
        }
      })
      // 插入代码运行结果
      codeTpms.forEach((el, index) => {
        const fragment = document.createDocumentFragment()
        const div = document.createElement('div')
        div.className = `code-result-box ${theme}`
        div.setAttribute('data-index', index)
        fragment.appendChild(div)
        el.parentElement.appendChild(fragment)
      })
      await this.$nextTick()
      const runBtns = document.querySelectorAll('.runCode')
      const interBtns = document.querySelectorAll('.aiInterpretation')
      runBtns.forEach(el => {
        el.addEventListener('click', this.handlerRunCodeClick(el))
      })
      interBtns.forEach(el => {
        el.addEventListener('click', this.handlerInterClick(el))
      })
      codeTpms.forEach((el, i) => {
        const value = el.innerHTML.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&amp;', '&') + `${test ? '\r\n\r\n\r\n\r\n\r\n' : '\r\n'}`
        this.initEditor(el, value, false, theme, i)
      })
      staticCode.forEach((el, i) => {
        const value = el.innerHTML.replaceAll('&nbsp;&nbsp;', ' ')
        this.initEditor(el, value, true, theme, i)
      })
    },
    // 处理运行按钮点击事件
    handlerRunCodeClick(el) {
      return () => {
        this.codeResultArr = []
        const index = el.parentElement.parentElement.dataset.index
        const currentValue = this.editors[index].editor.getValue()
        this.runPython(currentValue)
        this.renderCodeResult(index)
      }
    },
    // 处理AI释义按钮点击事件
    handlerInterClick(el) {
      const { lessons } = this.$route.params
      return () => {
        this.codeResultArr = []
        const index = el.parentElement.parentElement.dataset.index
        const currentValue = this.editors[index].editor.getValue()
        let content = `${currentValue}`
        if (!lessons) {
          const contextEle = document.querySelectorAll('.language-context')[index]
          content = `
            题目: ${contextEle.innerText}\r\n
            答案: ${currentValue}
          `
        }
        EventBus.$emit('ai-ask-change', { content, currentValue }, lessons)
      }
    },
    // 初始化编辑器
    async initEditor (el, value, readOnly = false, theme = 'vs-dark', index = 0) {
      this.removeListener()
      function autoResize (editor) {
        const lineHeight = editor.getOption(monaco.editor.EditorOption.lineHeight) + 2
        const lineCount = editor.getModel().getLineCount()
        editor.getDomNode().style.height = `${lineCount * lineHeight}px`
        editor.getDomNode().style.width = '100%'
        editor.layout()
      }
      el.innerHTML = ''
      this.editorOptions.theme = theme
      this.editorOptions.value = value
      this.editorOptions.readOnly = readOnly
      const editor = monaco.editor.create(el, this.editorOptions)
      // 监听编辑器内容变化
      editor.onDidChangeModelContent(debounce(() => {
        this.saveHomework(index)
      }, 3000))
      const scrollElement = document.querySelector('#mdContainer').parentNode
      const wheelHandler = (e) => {
        scrollElement.scrollBy(0, e.deltaY > 0 ? 10 : -10)
      }
      const onWheelHandler = editor.onMouseWheel(wheelHandler)
      await this.$nextTick()
      autoResize(editor)
      const contentChangeHandler = () => {
        // console.log('内容变化')
        autoResize(editor)
      }
      const onContentChangeHandler = editor.onDidChangeModelContent(contentChangeHandler)
      if (!readOnly) {
        this.editors.push({
          onWheelHandler,
          onContentChangeHandler,
          editor
        })
      }
    },
    // 移除监听器
    removeListener() {
      const runBtns = document.querySelectorAll('.runCode')
      const interBtns = document.querySelectorAll('.aiInterpretation')
      runBtns.forEach(el => {
        el.removeEventListener('click', this.handlerRunCodeClick(el))
      })
      interBtns.forEach(el => {
        el.removeEventListener('click', this.handlerInterClick(el))
      })
      this.editors.forEach(({ editor, onWheelHandler, onContentChangeHandler }) => {
        onWheelHandler.dispose()
        onContentChangeHandler.dispose()
        editor.dispose()
      })
      this.editors = []
    },
    // 运行结果展示
    renderCodeResult(index) {
      const codeResElements = document.querySelectorAll('.code-result-box')
      const curEl = codeResElements[index]
      curEl.innerHTML = ''
      const div = document.createElement('div')
      let codeResultStr = this.codeResultStr
      if (this.codeError) {
        codeResultStr = `Error:\r\n\r\n${codeResultStr}`
        div.className = 'code-result-content error'
      } else {
        codeResultStr = `Result:\r\n\r\n${codeResultStr}`
        div.className = 'code-result-content success'
      }
      div.innerText = codeResultStr
      curEl.appendChild(div)
    },
    // 运行python代码
    async runPython(code) {
      this.codeResultArr = []
      this.codeResultStr = ''
      this.codeError = false
      try {
        this.pyodide.runPython(code)
      } catch (e) {
        // replace error message
        e.message = this.reformat_exception()
        if (e.message.includes('I/O error') && code.indexOf('while') === -1) {
          const newCode = this.handlerInputCode(code)
          console.log(newCode)
          this.runPython(newCode)
          return
        }
        this.codeError = true
        this.codeResultStr = e.message.match(/(File "<exec>")(.|\r\n|\r|\n)+/g)[0]
      }
    },
    handlerInputCode(code) {
      // debugger
      code = code.replaceAll(/#(.*?)(?=\n|$)/g, '')
      const reg = /input\((.*?)\)/
      const reg1 = /"(.*?)"/
      const reg2 = /'(.*?)'/
      if (reg.test(code)) {
        let result = code.match(reg)[1]
        console.log(code.match(reg))
        if (reg1.test(result)) {
          result = result.match(reg1)[1]
        } else if (reg2.test(result)) {
          result = result.match(reg2)[1]
        }
        const userInputStr = prompt(result)
        this.userQuestions.push(result)
        return code.replace(`input(${code.match(reg)[1]})`, `"${userInputStr}"`)
      }
    },
    // 获取子菜单项
    getSubMenu() {
      const { test } = this.$route.params
      if (test) {
        return EventBus.$emit('sub-menu-change', getItem('subMenuArr') || [])
      }
      const subMenus = document.querySelectorAll('.atx')
      const subMenuArr = Array.from(subMenus).map(item => {
        return {
          title: item.outerText,
          id: item.id
        }
      })
      // console.log(subMenuArr)
      setItem(subMenuArr, 'subMenuArr')
      EventBus.$emit('sub-menu-change', subMenuArr)
    },
    // 获取作业列表
    async getHomeworkList() {
      const { test } = this.$route.params
      if (!test) return
      const userId = this.userId
      const courseId = Number(test) + 1
      const { data } = await getHomeWorkListApi(userId, courseId)
      this.homeworkList = data
      this.homeworkList.forEach((item, index) => {
        if (this.editors[index]) {
          this.editors[index].editor.setValue(item.homeworkContent)
        }
      })
    },
    // 保存用户作业练习
    async saveHomework(index) {
      if (!this.$route.params.test) return
      if (!this.homeworkList[index]) {
        await this.getHomeworkList()
      }
      if (!this.editors[index]) return
      const { test } = this.$route.params
      const params = {
        courseSubdirectoryId: Number(test) + 1,
        customerId: this.userId,
        homeworkContent: this.editors[index].editor.getValue()
      }
      if (!this.homeworkList[index]) {
        // todo: 新增作业
        await addHomeWorkApi(params)
        this.getHomeworkList()
      } else {
        // todo: 修改作业
        const { id } = this.homeworkList[index]
        params.id = id
        await editHomeWorkApi(params)
      }
    }
  },
  beforeDestroy() {
    this.removeListener()
  }
}
</script>
<style lang="scss">
#mdContainer {
  padding-bottom: 20px;
  .markdown-body {
    padding-top: 50px;
    margin: 0;
    max-width: 100%;
    table tr td {
      text-align: center;
    }
    pre {
      padding: 0;
      margin-bottom: 0;
      font-size: 0;
      code {
        font-size: 16px;
      }
    }
    hr {
      height: 2px;
      margin-top: 80px;
      margin-bottom: 40px;
    }
    .atx {
      border-bottom: none;
    }
    li>p {
      margin-top: 0px;
    }
    ol {
      padding-left: 1em;
    }
    code {
      font-size: 100%;
    }
    h1:nth-child(1) {
      font-size: 40px;
    }
    p {
      margin-bottom: 15px;
    }
  }

  .markdown-body pre code {
    display: inline-block;
    width: 100%;
    min-height: 40px;

  }

  .monaco-editor .view-lines {
    text-shadow: none !important;
    .mtk7 {
      color: #a0a0a0;
    }
  }

  .monaco-editor .line-numbers {
    text-shadow: none !important;
  }

  .language-python, .language-js {
    line-height: 40px;
  }

  .language-context {
    padding: 20px;
    font-size: 22px !important;
    box-sizing: border-box;
    line-height: 40px;
    white-space: pre-wrap;
  }

  .markdown-body pre {
    margin-bottom: 10px;
  }

  .code-oper-box {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    background: #fff;
    padding-bottom: 20px;
    border-radius: 6px 6px 0 0;
    .btns {
      display: flex;
    }
    .btn-item {
      width: 138px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 4px;
      background: #6a50c9;
      margin-left: 10px;
      cursor: pointer;
      font-size: 16px;
    }
    .btn-item:hover {
      background: #835AFD;
    }
  }
  .code-oper-box.vs-dark {
    background: #1f1e1e;
  }
  .code-result-box {
    position: relative;
    top: -2px;
    width: 100%;
    min-height: 20px;
    border-radius: 0 0 6px 6px;
    .code-result-content {
      width: 100%;
      min-height: 100px;
      padding: 20px;
      color: #fff;
      font-size: 18px;
    }
    .code-result-content.error {
      background: red;
    }
    .code-result-content.success {
      background: #DBFBDC;
      color: #1f1e1e;
    }
  }
  .code-result-box.vs-dark {
    background: #1f1e1e;
  }

  .language-js, .language-python, .language-Python {
    background: #f5f6f7;
    border-radius: 6px;
    border: 1px solid #dee0e3;
    padding: 20px;
    box-sizing: border-box;
    font-size: 18px !important;
  }
  p {
    margin-bottom: 0;
    line-height: 40px;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 25px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
}
</style>
