<template>
  <div
    class="qa-page"
    :class="{ 'home': $route.path === '/home' }"
    :style="{ background: $route.path.includes('/qa') ? 'linear-gradient(to bottom, #e7e1fd 0%, #fff 50%, #fff 100%)' : '' }">
    <div class="container hidden-xs-only">
      <div class="header">常见问题</div>
      <div class="qa-item" v-for="(item, index) in qaData" :key="index">
        <div class="question">Q: {{ item.q }}</div>
        <div class="answer">A: {{ item.a }}</div>
      </div>
    </div>
    <div class="container hidden-sm-and-up">
      <div class="header">常见问题</div>
      <div class="qa-item" v-for="(item, index) in qaData" :key="index">
        <div class="question">Q: {{ item.q }}</div>
        <div class="answer">A: {{ item.a }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import QA from '@/constant/qa'
export default {
  name: 'qa',
  data() {
    return {
      qaData: QA
    }
  },
  mounted() {
    if (this.$route.path.includes('/qa')) this.$store.dispatch('listenScroll')
  },
  beforeDestroy() {
    if (this.$route.path.includes('/qa')) this.$store.dispatch('removeScroll')
  }
}
</script>
<style scoped lang="scss">
.qa-page {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  // background: linear-gradient(to bottom, #e7e1fd 0%, #fff 50%, #fff 100%);
  .container {
    width: 85%;
    padding-bottom: 100px;
    .header {
      width: 100%;
      text-align: center;
      font-size: 60px;
      font-weight: bold;
      margin-bottom: 50px;
    }
    .qa-item {
      width: 100%;
      margin-bottom: 40px;
      .question {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 10px;
        line-height: 60px;
      }
      .answer {
        line-height: 50px;
        color: #777;
        font-size: 25px;
      }
    }
  }
  .container.hidden-sm-and-up {
    width: 90%;
    padding-bottom: 200px;
    .header {
      font-size: 80px;
      margin-bottom: 100px;
    }
    .qa-item {
      width: 100%;
      margin-bottom: 80px;
      .question {
        font-size: 70px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .answer {
        line-height: 90px;
        color: #777;
        font-size: 60px;
      }
    }
  }
}
.qa-page.home {
  padding-top: 0;
  .container {
    .header {
      color: #bbb;
    }
    .qa-item {
      .question {
        color: #bbb;
      }
      .answer {
        color: #bbb;
      }
    }
  }
}
</style>
