<template>
  <div class="login-dialog">
    <el-dialog
      :show-close="false"
      :visible.sync="dialogVisible"
      width="fit-content">
      <div class="wx-login-container hidden-xs-only">
        <div class="header">
          <span>欢迎加入跟ChatGPT学编程</span>
          <span>请使用微信扫一扫登录网站</span>
        </div>
        <div class="qrCode-container">
          <span>扫描后关注公众号，完成登录</span>
          <div class="img-content">
            <img src="@/assets/img/wechat-avtar.jpg" alt="" class="wechat_avatar">
            <img class="qr-code" :src="qrCodeUrl" alt="">
          </div>
        </div>
      </div>
      <div class="wx-login-container hidden-sm-and-up">
        <div class="header">
          <span>欢迎加入跟ChatGPT学编程</span>
          <span>请使用微信登录网站</span>
        </div>
        <div class="qrCode-container">
          <!-- <el-button type="primary" @click="loginByWx">关注公众号完成登录</el-button> -->
          <span>长按识别关注公众号，完成登录</span>
          <div class="img-content">
            <img src="@/assets/img/wechat-avtar.jpg" alt="" class="wechat_avatar">
            <img class="qr-code" :src="qrCodeUrl" alt="">
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex'
export default {
  name: 'login-dialog',
  data() {
    return {
      dialogVisible: false,
      qrCodeUrl: ''
    }
  },
  // computed: {
  //   ...mapGetters(['wxURL'])
  // },
  methods: {
    open(ticket) {
      this.qrCodeUrl = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${ticket}`
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
    }
    // loginByWx() {
    //   location.href = this.qrCodeUrl
    // }
  }
}
</script>
<style scoped lang="scss">
.login-dialog {
  border-radius: 6px;
  background: #fff;
  overflow: hidden;
  ::v-deep(.el-dialog__header), ::v-deep(.el-dialog__body) {
    padding: 0;
    padding-bottom: 0;
  }
}
.wx-login-container {
  width: 400px;
  .header {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #5ac485;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
  }
  .qrCode-container {
    width: 100%;
    height: 350px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .img-content {
      position: relative;
      .wechat_avatar {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
      }
    }
    .qr-code {
      flex-direction: column;
      width: 250px;
      height: 250px;
      margin-top: 10px;
    }
  }
}
.wx-login-container.hidden-sm-and-up {
  width: 1200px;
  .header {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #5ac485;
    color: #fff;
    font-size: 70px;
    line-height: 100px;
  }
  .qrCode-container {
    width: 100%;
    height: 1000px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .img-content {
      position: relative;
      .wechat_avatar {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
      }
    }
    .qr-code {
      flex-direction: column;
      width: 800px;
      height: 800px;
      margin-top: 30px;
    }
  }
}
</style>
