<template>
  <div class="guide-step2">
    <div class="target_container" :style="{ left: `${targetEl.left - 20}px`, top: `${targetTop}px`, width: `${targetWidth}px`, height: `${targetWidth}px` }">
      <div class="target_btn" :style="{ width: `${targetEl.width}px`, height: `${targetEl.height}px` }">运行</div>
      <div class="tips-container">
        <div class="process">2 / 3</div>
        <div class="desc">尝试点击"运行"按钮, 查看代码运行结果。</div>
        <div class="btn_box">
          <div class="btn1" @click="guideSkip">跳过</div>
          <div class="btn2" @click="nextStep">下一步</div>
        </div>
        <div class="triangle"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTargetDOMClint } from '@/utils/dom'
import { mapMutations } from 'vuex'
import { setItem } from '@/utils/storage'
import EventBus from '@/components/course/EventBus'
export default {
  name: 'guide-step2',
  data() {
    return {
      targetEl: {}
    }
  },
  computed: {
    targetWidth() {
      return this.targetEl.width + 40
    },
    targetTop() {
      return this.targetEl.top - (this.targetWidth - this.targetEl.height) / 2
    }
  },
  mounted() {
    setTimeout(() => {
      this.targetEl = getTargetDOMClint('.runCode')
    }, 0)
  },
  methods: {
    ...mapMutations(['SET_MASK_ACTIONS', 'SET_CHECK_ROUTE']),
    guideSkip() {
      EventBus.$emit('guide-change', 'skip')
    },
    nextStep() {
      this.SET_CHECK_ROUTE(false)
      this.SET_MASK_ACTIONS('step4')
      setItem('step4', 'guide')
      EventBus.$emit('guide-change')
    }
  }
}
</script>
<style scoped lang="scss">
@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.guide-step2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  .target_container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: transparent;
    // border-radius: 50%;
    .target_btn {
      background: #6a50c9;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      color: #fff;
      // animation: scaleAnimation 1s ease-in-out infinite;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }

    .tips-container {
      position: absolute;
      right: 50px;
      bottom: 160px;
      box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.22);
      border: 1px solid #835AFD;
      padding: 14px 20px;
      box-sizing: border-box;
      width: 380px;
      z-index: 999;
      background: #fff;
      .process {
        font-weight: 500;
        color: #835AFD;
        line-height: 40px;
      }
      .desc {
        font-weight: 500;
        color: #191919;
        line-height: 40px;
        font-size: 16px;
      }
      .btn_box {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;
        .btn1, .btn2 {
          width: 80px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          font-size: 16px;
          margin-left: 5px;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
        .btn1 {
          background: #FFFFFF;
          border-radius: 2px;
          border: 1px solid #414141;
        }
        .btn2 {
          background: #835AFD;
          border-radius: 2px;
          color: #fff;
        }
      }
      .triangle {
        position: absolute;
        width: 20px;
        height: 20px;
        background: #fff;
        border-left: 1px solid #835AFD;
        border-bottom: 1px solid #835AFD;
        transform: rotate(-45deg);
        bottom: -12px;
        right: 30px;
      }
    }
    .right-line {
      position: absolute;
      right: 0;
      bottom: 100px;
      width: 2px;
      height: 200px;
      border-right: 2px dashed #fff;
    }
  }
}
</style>
