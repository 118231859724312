import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { HandlerMessage } from '@/utils/element'
import ElementUI from 'element-ui'
import clipboard from './utils/clipboard'
// import 'normalize.css/normalize.css'
import '@/assets/iconfont/iconfont.css'
import './elementUI/element-variables.scss'
import 'element-ui/lib/theme-chalk/display.css'

Vue.use(ElementUI, { size: 'medium', zIndex: 3000 })

Vue.prototype.$message = new HandlerMessage()

Vue.config.productionTip = false

Vue.directive('clipboard', clipboard)

Vue.directive('typewriter', {
  bind: (el, binding) => {
    const text = el.innerText
    let delay = 20
    if (text.length < 30) {
      delay = 40
    }
    if (text.length > 150) {
      delay = 10
    }
    if (text.length > 300) {
      delay = 5
    }
    let index = 0
    el.innerText = ''

    const intervalId = setInterval(function () {
      if (index < text.length) {
        el.innerText += text[index]
        index++
        const loadFn = binding.value
        if (typeof loadFn === 'function') {
          loadFn()
        }
      } else {
        clearInterval(intervalId)
      }
    }, delay)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
