import service from '@/utils/request'

// 1. 回答问题
export const askGPTApi1 = message => service.post('/api/chat/completions', {
  type: '1',
  frequencyPenalty: '0',
  // maxTokens: '800',
  message,
  presencePenalty: '0',
  stop: 'None',
  temperature: '0.3'
  // topP: '0.95'
})

// 2. 解释代码
export const askGPTApi2 = message => service.post('/api/chat/completions', {
  type: '1',
  frequencyPenalty: '0',
  // maxTokens: '800',
  message,
  presencePenalty: '0',
  stop: 'None',
  temperature: '0.3'
  // topP: '0.95'
})

// 3. 判题
export const askGPTApi3 = message => service.post('/api/chat/completions', {
  type: '2',
  frequencyPenalty: '0',
  // maxTokens: '800',
  message,
  presencePenalty: '0',
  stop: 'None',
  temperature: '0.1'
  // topP: '0.95'
})
