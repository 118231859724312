<template>
  <div class="shortcut">
    <div class="shortcut-content">
      <div
        class="shortcut-item"
        v-clipboard:copy="inviterUrl"
        v-clipboard:success="copySuccess"
      >
        <el-popover
          placement="left-start"
          width="200"
          trigger="hover">
          <div class="copy-content">
            <div class="text">邀请好友一起加入《跟ChatGPT学Python》！通过您的专属链接邀请好友注册后，您和您的好友均可免费获得额外的 10 次GPT-4问答机会。</div>
            <div class="btn">
              <el-button
                type="primary"
                size="mini"
                v-clipboard:copy="inviterUrl"
                v-clipboard:success="copySuccess"
              >复制专属链接</el-button>
            </div>
          </div>
          <template  #reference>
            <i class="iconfont icon-fenxiang"></i>
          </template>
        </el-popover>
      </div>
      <div class="shortcut-item" @click="shortcutAction(2)">
        <el-tooltip effect="dark" content="评论" placement="left">
          <i class="iconfont icon-pinglun"></i>
        </el-tooltip>
      </div>
      <div class="shortcut-item" @click="shortcutAction(3)">
        <el-tooltip effect="dark" :content="isCollapse ? '退出沉浸式阅读' : '开启沉浸式阅读'" placement="left">
          <i class="iconfont icon-quanping" :class="{ isCollapse }"></i>
        </el-tooltip>
      </div>
      <div class="shortcut-item" @click="shortcutAction(4)">
        <el-tooltip effect="dark" content="置顶" placement="left">
          <i class="iconfont icon-zhiding"></i>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '@/components/course/EventBus'
import { mapGetters } from 'vuex'
export default {
  name: 'shortcut',
  computed: {
    ...mapGetters(['inviterUrl'])
  },
  data() {
    return {
      isCollapse: false
    }
  },
  methods: {
    shortcutAction(type) {
      if (type === 3) this.isCollapse = !this.isCollapse
      EventBus.$emit('shortcut-action', type, this.isCollapse)
    },
    copySuccess() {
      this.$message.success('邀请链接已复制!')
    }
  }
}
</script>
<style scoped lang="scss">
.shortcut {
  position: fixed;
  right: 15px;
  bottom: 200px;
  .shortcut-item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eee;
    border-radius: 50%;
    margin-bottom: 10px;
    cursor: pointer;
    .iconfont {
      font-size: 20px;
    }
    .iconfont.isCollapse {
      color: #835AFD;
    }
  }
  .shortcut-item:hover {
    background: #835AFD;
    .iconfont {
      color: #fff;
    }
  }
}
</style>
<style lang="scss">
.copy-content {
  padding: 10px 0;
  .btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
</style>
