<template>
  <div class="guide-step1">
    <div class="target_container" :style="{ left: `${targetEl.left}px`, top: `${targetEl.top}px`, width: `${targetEl.width}px`, height: `${targetEl.height}px` }">
      <el-input v-model="qText" class="textarea" type="textarea" :rows="5" resize="none" placeholder="在此输出您想了解的内容"></el-input>
      <div class="submit-icon active">
        <i class="el-icon-s-promotion"></i>
      </div>
      <div class="tips-container">
        <div class="process">1 / 3</div>
        <div class="desc">向ChatGPT 询问一个Python问题</div>
        <div class="btn_box">
          <div class="btn1" @click="guideSkip">跳过</div>
          <div class="btn2" @click="nextStep">下一步</div>
        </div>
        <div class="triangle"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTargetDOMClint } from '@/utils/dom'
import EventBus from '@/components/course/EventBus'
import { mapMutations } from 'vuex'
import { setItem } from '@/utils/storage'
export default {
  name: 'guide-step1',
  data() {
    return {
      qText: '',
      targetEl: {}
    }
  },
  mounted () {
    this.targetEl = getTargetDOMClint('#target-step1')
  },
  methods: {
    ...mapMutations(['SET_OPEN_MASK', 'SET_MASK_ACTIONS']),
    nextStep() {
      this.SET_MASK_ACTIONS('step2')
      setItem('step2', 'guide')
      EventBus.$emit('guide-change')
    },
    guideSkip() {
      EventBus.$emit('guide-change', 'skip')
    }
  }
}
</script>
<style scoped lang="scss">
@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.guide-step1 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: transparent;
  .target_container {
    position: absolute;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(32,0,126,0.48);
    border: 1px solid #E9E2FF;
    .submit-icon {
      cursor: pointer;
      position: absolute;
      width: 26px;
      height: 26px;
      bottom: 10px;
      right: 10px;
      background: #bcbcbc;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        opacity: 0.8;
      }
      i {
        color: #fff;
      }
    }
    .submit-icon.active {
      background: #835AFD;
      // animation: scaleAnimation 1s ease-in-out infinite;
    }
    ::v-deep(.el-textarea__inner) {
      font-size: 20px;
    }
    .tips-container {
      position: absolute;
      right: 30px;
      bottom: 200px;
      box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.22);
      border: 1px solid #835AFD;
      padding: 14px 20px;
      box-sizing: border-box;
      .process {
        font-weight: 500;
        color: #835AFD;
        line-height: 40px;
      }
      .desc {
        font-weight: 500;
        color: #191919;
        line-height: 40px;
        font-size: 16px;
      }
      .btn_box {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;
        .btn1, .btn2 {
          width: 80px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          font-size: 16px;
          margin-left: 5px;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
        .btn1 {
          background: #FFFFFF;
          border-radius: 2px;
          border: 1px solid #414141;
        }
        .btn2 {
          background: #835AFD;
          border-radius: 2px;
          color: #fff;
        }
      }
      .triangle {
        position: absolute;
        width: 20px;
        height: 20px;
        background: #fff;
        border-left: 1px solid #835AFD;
        border-bottom: 1px solid #835AFD;
        transform: rotate(-45deg);
        bottom: -12px;
        right: 30px;
      }
    }
  }
}
</style>
