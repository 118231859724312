<template>
  <div class="course-info-c">
    <!-- <div v-if="lessons" class="course-header">
      <div class="course-icon">
        <img src="@/assets/img/ai-logo.png" alt="">
      </div>
      <div class="course-title">
        <div class="title-text font-bold">{{ courseTitle }}</div>
        <div class="course-label font-18">这里是标签内容</div>
      </div>
    </div> -->
    <!-- 课程详情渲染 -->
    <render-content />
    <div v-if="commentShow && lessons !== '0'" class="test-tips">
      <h1>编程练习</h1>
      <div class="tip-text" @click="toTest">点击链接，开始本节课的编程练习吧！</div>
    </div>
    <comment v-if="commentShow" />
  </div>
</template>
<script>
import renderContent from './renderContent.vue'
import comment from '@/views/python/components/comment'
export default {
  name: 'course-info-c',
  components: {
    renderContent,
    comment
  },
  watch: {
    $route: {
      handler() {
        const { test } = this.$route.params
        if (test) {
          this.commentShow = false
        } else {
          this.commentShow = true
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      courseTitle: '',
      commentShow: true
    }
  },
  computed: {
    lessons() {
      return this.$route.params.lessons
    }
  },
  methods: {
    toTest() {
      const { lessons } = this.$route.params
      this.$router.push(`/python/test/${lessons}`)
    }
  }
}
</script>
<style scoped lang="scss">
.course-info-c {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .course-header {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 156px;
    padding-bottom: 20px;
    .course-icon {
      width: 96px;
      height: 96px;
      img {
        width: 96px;
        height: 96px;
      }
    }
    .course-title {
      padding-left: 25px;
      display: flex;
      flex-direction: column;
      .title-text {
        font-size: 26px;
        color: #18191c;
        line-height: 48px;
      }
      .course-label {
        line-height: 48px;
      }
    }
  }
  .test-tips {
    color: #000;
    .tip-text {
      display: inline-block;
      color: #2955e7;
      cursor: pointer;
    }
    .tip-text:hover {
      border-bottom: 1px solid #835AFD;
    }
  }
}
</style>
