import service from '@/utils/request'
import { getItem } from '@/utils/storage'
import { EncryptData } from '@/utils'
const KEY = 'UoJNKCaZRr1sBgiBD9wZtMdHUUABUk0I'
// 发送短信验证码
export const sendCodeApi = data => service.post('/api/phoneLogin/message', data)

// 用户登录
export const loginApi = data => service.put('/api/phoneLogin/message', data)

// 获取用户信息
export const userInfoApi = () => service.get('/api/user/getCustomerStatistics')

// 获取微信登录二维码
const WxQrCodeParams = {
  key: KEY,
  iCode: getItem('icode')
}
export const getWxQrCodeApi = () => service.post('/api/mp/createWxLoginQrCode', EncryptData(JSON.stringify(WxQrCodeParams)), {
  headers: {
    'Content-Type': 'application/json'
  }
})

// 获取用户微信登录信息
export const getUserInfoByWxApi = ticket => service.get(`/api/user/loginByTicket?ticket=${ticket}`)

// 获取用户数
export const getUserCountApi = () => service.get('/api/user/userCount')
