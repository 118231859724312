<template>
  <div class="ai-chat-box" :class="{ open, close: !open }">
    <!-- 头部 -->
    <div class="chat-header font-bold font-18">
      <span>ChatGPT</span>
      <div class="ai-toggle-btn" @click="toggleChatBox">
        <!-- <span>AI导师</span>
        <i :class="open ? 'el-icon-right' : 'el-icon-back'"></i> -->
        <!-- <img src="@/assets/img/closeAi.svg" alt=""> -->
        <el-tooltip class="item" effect="dark" content="收起" placement="top-start">
          <i class="iconfont icon-zhedie"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 内容区域 -->
    <div class="chat-container">
      <div class="chat-content">
        <template v-for="(item, index) in chatList">
          <div v-if="item.role === 'user'" class="person-item" :key="index">
            <div v-html="item.content" class="question font-18"></div>
            <div class="q-icon">
              <img src="@/assets/img/avatar.png" alt="">
            </div>
          </div>
          <div v-else class="ai-item" :key="index">
            <div class="a-icon">
              <img src="@/assets/img/logo.svg" alt="">
            </div>
            <div v-html="_marked(item.content)" class="answer font-18"></div>
          </div>
          <div class="pay-btn-box" :key="item.code + index" v-if="item.code === '6001'">
              <el-button type="primary" size="mini" @click="$router.push('/payment')">立即续费</el-button>
          </div>
          <div v-if="loading && index === chatList.length - 1" class="ai-item" :key="'loading' + index">
            <div class="a-icon">
              <img src="@/assets/img/logo.svg" alt="">
            </div>
            <div class="answer font-18">
              <div>{{ loadingText }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 底部 -->
    <div class="chat-footer">
      <div id="target-step1" class="footer-container">
        <el-input v-model="qText" class="textarea" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" resize="none" placeholder="向ChatGPT提问"></el-input>
        <div class="submit-icon" :class="{ active: qText.length > 0 }" @click="handlerSubmit">
          <i class="el-icon-s-promotion"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { askGPTApi1, askGPTApi2, askGPTApi3 } from '@/api/chat'
import EventBus from './EventBus'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { addCourseRecodeApi } from '@/api/course'
import { setItem, getItem } from '@/utils/storage'
export default {
  name: 'ai-chat-box',
  props: {
    open: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(['guideFlag']),
    ...mapGetters(['userId', 'nickName'])
  },
  data () {
    return {
      qText: '',
      chatList: [{ role: 'assistant', content: 'Hi，你好呀！我是你的专属编程导师，欢迎随时向我提出关于编程的问题。每一个问题都是进步的契机。' }], // 聊天列表 [{ role: 'user', content: '' }, { role: 'assistant', content: '' }]
      loading: false,
      type: null,
      qContent: '',
      timer: null,
      loadingText: '我正在努力思考，请耐心等待',
      lodingIndex: 0
    }
  },
  watch: {
    loading (val) {
      if (val) {
        this.startLoaingText()
      } else {
        this.stopLoadingText()
      }
    },
    chatList(data) {
      const chatData = getItem('CHATLISTBYNICKNAME') || {}
      chatData[this.nickName] = data.slice(-200)
      setItem(chatData, 'CHATLISTBYNICKNAME')
    }
  },
  created() {
    EventBus.$on('ai-ask-change', ({ content, currentValue }, type) => {
      this.type = type
      this.qText = currentValue
      this.qContent = content
      this.submit()
    })
    EventBus.$on('shortcut-action', (type, isCollapse) => {
      if (type === 3 && isCollapse === true) {
        this.toggleChatBox(isCollapse)
      }
    })
    this.initChatData()
  },
  mounted () {
    this.overLoadChatContent()
  },
  methods: {
    ...mapMutations(['SET_OPEN_MASK', 'SET_MASK_ACTIONS']),
    initChatData() {
      const chatData = getItem('CHATLISTBYNICKNAME')
      if (chatData && chatData[this.nickName]) {
        this.chatList = chatData[this.nickName]
      }
    },
    startLoaingText() {
      const texts = ['我正在努力思考，请耐心等待', '我正在努力思考，请耐心等待.', '我正在努力思考，请耐心等待..', '我正在努力思考，请耐心等待...']
      this.timer = setInterval(() => {
        this.lodingIndex += 1
        this.loadingText = texts[this.lodingIndex % texts.length]
      }, 500)
    },
    stopLoadingText() {
      clearInterval(this.timer)
      this.timer = null
      this.lodingIndex = 0
    },
    _marked(str) {
      let newStr = str
      try {
        newStr = marked.marked(str)
      } catch (error) {
        console.log(error)
      }
      return newStr
    },
    toggleChatBox(isCollapse) {
      if (isCollapse === true) return this.$emit('open', false)
      this.$emit('open', !this.open)
    },
    // 添加成功课程记录
    addCourseRecode() {
      const { test } = this.$route.params
      const params = {
        courseSubdirectoryId: Number(test) + 1,
        customerId: this.userId,
        status: '1',
        nextCourseSubdirectoryId: Number(test) + 2
      }
      addCourseRecodeApi(params)
    },
    handlerSubmit() {
      this.type = 'custom'
      this.submit()
    },
    async submit() {
      if (this.qText.length === 0) return
      this.loading = true
      this.chatList.push({ role: 'user', content: this.qText })
      await this.$nextTick()
      this.overLoadChatContent()
      const content = [{ role: 'user', content: this.qContent || this.qText }]
      if (this.type === 'custom') {
        // 自定义问题
        content.unshift({ role: 'system', content: '你是一位专业、亲和且善于鼓励学员的Python编程导师，你使用清晰易懂的中文详细解答与Python编程有关的问题。对于复杂的概念，你会通过举例的方式进一步阐述。对于与编程无关的回答，你将回复：”非常抱歉，我是一名Python编程导师，只回答与Python编程相关的问题。请问您有与Python编程相关的问题吗？' })
      } else if (!this.type) {
        // 判题
        content.unshift({ role: 'system', content: '请判断提交的“答案”（Python代码）是否满足“题目”的要求。如果“答案”满足“题目”的全部要求，你回复：“太棒啦！你提交的代码满足题目的全部要求，在学会Python编程的道路上又前进了一大步。请继续加油哦！”。如果“答案”不满足“题目”的全部要求，你会指出代码中存在的问题，但不要直接告诉学员正确的代码。' })
      } else {
        // 解释
        content.unshift({ role: 'system', content: '你是一位Python编程专家，你将解释代码中关键知识点的用法。' })
      }
      const message = JSON.stringify(content)
      this.qText = ''
      this.qContent = ''
      await this.getAnswerByGPT(message)
      this.loading = false
      await this.$nextTick()
      this.overLoadChatContent()
    },
    overLoadChatContent() {
      const chatContainerEle = document.querySelector('.chat-container')
      chatContainerEle.scrollBy(0, 100000)
    },
    // 设置prompt
    // setGPTPrompt() {
    //   askGPTApi('[{"role": "system", "content": "你现在是一位python老师。"}]')
    // },
    // 获取问题答案
    async getAnswerByGPT(message) {
      //   message: '[{"role": "system", "content": "你现在是一位python老师。"},{"role": "user", "content": "写一个坦克大战的代码？"}]'
      let chatFn = askGPTApi2
      if (this.type === 'custom') {
        chatFn = askGPTApi1
      }
      if (!this.type) {
        chatFn = askGPTApi3
      }
      const { data, code } = await chatFn(this.handlerContext(message))
      if (!this.type) {
        const regStr1 = /太棒啦/
        const regEx1 = new RegExp(regStr1)
        if (regEx1.test(data)) {
          const timer = setTimeout(() => {
            this.SET_OPEN_MASK(true)
            this.SET_MASK_ACTIONS('congratulation')
            clearTimeout(timer)
          }, 5000)
          this.addCourseRecode()
        }
      }
      this.type = null
      // 更新用户信息并检查GPT次数
      this.$store.dispatch('userInfoAction', code !== '6001')
      this.chatList.push({ role: 'assistant', content: data, code })
    },
    // 处理上下文逻辑
    handlerContext(currentMessage) {
      const contextNum = 3
      const chatData = getItem('CHATLISTBYNICKNAME')
      if (!chatData) return currentMessage
      const roles = ['user', 'assistant']
      const cacheData = chatData[this.nickName].filter((item, index) => {
        return roles.includes(item.role) && index !== 0 && index !== chatData[this.nickName].length - 1
      })
      const message = JSON.parse(currentMessage)
      message.splice(1, 0, ...cacheData.slice(-contextNum * 2))
      return JSON.stringify(message.map(item => {
        const obj = {}
        obj.role = item.role
        obj.content = item.content
        return obj
      }))
    }
  },
  beforeDestroy() {
    EventBus.$off('ai-ask-change')
    EventBus.$off('shortcut-action')
    this.stopLoadingText()
  }
}
</script>
<style scoped lang="scss">
@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.scaleAnimation {
  animation: scaleAnimation 1s ease-in-out infinite;
  animation-delay: 1s;
  font-weight: bold;
}
.pay-btn-box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  .el-button {
    height: 40px;
  }
}
.ai-chat-box {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #E9E2FF;
  overflow-y: auto;
  overflow-x: hidden;
  .font-18 {
    font-size: 19px;
    line-height: 30px;
  }
  .chat-header {
    position: relative;
    width: 100%;
    height: 68px;
    line-height: 68px;
    padding-left: 20px;
    border-bottom: 1px solid #E9E2FF;
    text-align: center;
    span {
      color: #835AFD;
      font-size: 22px;
      font-weight: bolder;
    }
    .ai-toggle-btn {
      position: absolute;
      height: 100%;
      left: 10px;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
      .iconfont {
        color: #835AFD;
        font-size: 30px;
      }
    }
    .ai-toggle-btn:hover {
      opacity: 0.8;
    }
  }
  .chat-container {
    display: flex;
    width: 100%;
    padding: 15px;
    height: calc(100% - 180px);
    // align-items: flex-end;
    overflow-y: auto;
    box-sizing: border-box;

    .chat-content {
      width: 100%;
      min-height: 100%;
      .person-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 20px;
        .q-icon {
          width: 36px;
          height: 36px;
          // background: linear-gradient(to top, #675df3, #FF59F8);
          border-radius: 16px;
          margin-left: 10px;
          overflow: hidden;
          img {
            width: 32px;
            height: 32px;
          }
        }
        .question {
          flex: 1;
          padding: 10px;
          background: rgba($color: #7d5cf4, $alpha: .1);
          border-radius: 4px;
          line-height: 35px;
          // text-align: right;
        }
      }
      .ai-item {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        .a-icon {
          width: 36px;
          height: 36px;
          border-radius: 16px;
          margin-right: 10px;
          img {
            width: 32px;
            height: 32px;
          }
        }
        .answer {
          // display: flex;
          flex: 1;
          padding: 10px;
          background: #f6f8fa;
          line-height: 35px;
          border-radius: 4px;
          width: 90%;
        }

        .loading {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .dot {
          width: 15px;
          height: 15px;
          margin: 0 5px;
          background: rgba(102, 81, 194, 1);
          border-radius: 50%;
          animation: load 1s infinite ease-in-out;
        }

        .dot:nth-child(2) {
          animation-delay: 0.2s;
        }

        .dot:nth-child(3) {
          animation-delay: 0.4s;
        }

        @keyframes load {
          0% {
            transform: scale(1);
            background: rgba(102, 81, 194, 1);
          }

          50% {
            transform: scale(1.5);
            background: rgba(102, 81, 194, 0.5);
          }

          100% {
            transform: scale(1);
            background: rgba(102, 81, 194, 1);
          }
        }
      }

    }
  }
  .chat-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    .footer-container {
      position: relative;
      width: 100%;
      // height: 110px;
      .submit-icon {
        cursor: pointer;
        position: absolute;
        width: 26px;
        height: 26px;
        bottom: 10px;
        right: 10px;
        background: #bcbcbc;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          color: #fff;
        }
        &:hover {
          opacity: 0.8;
        }
      }
      .submit-icon.active {
        background: #835AFD;
      }
      ::v-deep(.el-textarea__inner) {
        font-size: 20px;
      }
    }
  }
}
.ai-chat-box.close {
  display: none;
}
</style>
