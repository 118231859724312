const CryptoJS = require('crypto-js')
const key = CryptoJS.enc.Latin1.parse('0tom7ifx9jmmul68y7r099zrfmoehy1e')
// 加密
export const EncryptData = data => {
  const srcs = CryptoJS.enc.Utf8.parse(data)
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}

// 判断移动端环境
export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
