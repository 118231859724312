// 获取目标元素的位置信息
export const getTargetDOMClint = (target) => {
  const el = document.querySelector(target)
  const { top, left, width, height } = el.getBoundingClientRect()
  return {
    top,
    left,
    width,
    height
  }
}
