<template>
  <div class="course-detail" :class="{ hideNav }">
    <!-- 课程内容区域 -->
    <div class="course-container">
      <div class="course-content">
        <split-pane split="vertical" :min-percent="0" :default-percent="isCollapse ? 4: 15">
          <template slot="paneL">
            <div class="course-menu">
              <el-tooltip class="item" effect="dark" content="打开目录" placement="top-start">
                <i v-show="isCollapse" class="iconfont icon-mulu" @click="openMenu"></i>
              </el-tooltip>
              <course-menu
                ref="courseMenu"
                @change="isCollapse = $event"
              />
            </div>
          </template>
          <template slot="paneR">
            <split-pane split="vertical" :min-percent="0" :default-percent="aiAskOpen ? 70 : 96">
              <template slot="paneL">
                <div class="course-info">
                  <course-info />
                  <comment />
                </div>
              </template>
              <template slot="paneR">
                <div class="ai-ask" :class="{ open: aiAskOpen, close: !aiAskOpen }">
                  <chat-box :open="aiAskOpen" @open="aiAskOpen = $event" />
                  <div v-if="!aiAskOpen" class="ai-toggle-btn" @click="toggleChatBox">
                    <i :class="aiAskOpen ? 'el-icon-right' : 'el-icon-back'"></i>
                    <span>AI导师</span>
                  </div>
                </div>
              </template>
            </split-pane>
          </template>
        </split-pane>
      </div>
    </div>
    <shortcut />
  </div>
</template>
<script>
import courseMenu from '@/components/course/menu'
import courseInfo from '@/components/course/info'
import chatBox from '@/components/course/chatBox'
import EventBus from '@/components/course/EventBus'
import splitPane from 'vue-splitpane'
import { getItem, setItem } from '@/utils/storage'
import { mapState, mapGetters } from 'vuex'
import { addCourseRecodeApi } from '@/api/course'
import shortcut from './components/shortcut'
import { checkRoutePms } from '@/utils/permission'
import store from '@/store'
export default {
  name: 'course-detail',
  computed: {
    ...mapState(['guideFlag', 'courseInfo']),
    ...mapGetters(['userId'])
  },
  components: {
    courseMenu,
    courseInfo,
    splitPane,
    chatBox,
    shortcut
  },
  data () {
    return {
      isCollapse: true,
      openIconShow: true,
      aiAskOpen: true,
      breadcrumb1: getItem('breadcrumb1') || {},
      breadcrumb2: getItem('breadcrumb2') || {},
      hideNav: false
    }
  },
  mounted() {
    this.checkGuide()
    if (this.courseInfo.status === null || this.$route.params.lessons === '0') {
      this.addCourseRecode()
    }
  },
  created() {
    EventBus.$on('ai-ask-change', () => {
      if (!this.aiAskOpen) this.toggleChatBox()
    })
    EventBus.$on('guide-change', type => {
      // console.log(type)
      if (type === 'skip') {
        this.$store.commit('SET_OPEN_MASK', false)
        this.$store.commit('SET_MASK_ACTIONS', '')
        this.$store.commit('SET_GUIDE_FLAG', false)
        // window.payTimer = setTimeout(() => {
        //   this.$store.dispatch('goPaymentAction', '亲爱的读者，感觉不错的话请升级会员吧。')
        //   clearTimeout(window.payTimer)
        // }, 60000)
        return
      }
      // debugger
      const guideStr = getItem('guide')
      const steps = ['step1', 'step2', 'step3', 'step4', 'success']
      if (steps.includes(guideStr)) {
        if (guideStr === 'step4') {
          this.$router.push('/python/test/1')
        }
        this.$store.commit('SET_OPEN_MASK', true)
        if (guideStr === 'success') {
          this.$store.commit('SET_MASK_ACTIONS', '')
          this.$store.commit('SET_OPEN_MASK', false)
          this.$store.commit('SET_GUIDE_FLAG', false)
          this.$router.push('/python/lessons/1')
          // window.payTimer = setTimeout(() => {
          //   this.$store.dispatch('goPaymentAction', '亲爱的读者，感觉不错的话请升级会员吧。')
          //   clearTimeout(window.payTimer)
          // }, 60000)
        }
      }
    })
    EventBus.$on('shortcut-action', (type, isCollapse) => {
      if (type === 3 && isCollapse === false) {
        this.openMenu()
        this.toggleChatBox(isCollapse)
        this.hideNav = false
      } else if (type === 3 && isCollapse === true) {
        this.hideNav = true
      }
    })
  },
  methods: {
    openMenu () {
      this.isCollapse = false
      this.$refs.courseMenu.menuShow = true
    },
    toggleChatBox (isCollapse) {
      if (isCollapse === false) {
        this.aiAskOpen = true
        return
      }
      this.aiAskOpen = !this.aiAskOpen
    },
    checkGuide() {
      if (this.guideFlag) {
        this.$store.commit('SET_MASK_ACTIONS', 'step1')
        this.$store.commit('SET_OPEN_MASK', true)
      }
    },
    // 添加课程记录
    async addCourseRecode() {
      const { lessons } = this.$route.params
      const params = {
        courseSubdirectoryId: Number(lessons) + 1,
        customerId: this.userId,
        status: lessons === '0' ? '1' : '0',
        homeworkContent: ''
        // nextCourseSubdirectoryId: Number(lessons) + 2
      }
      const { success, msg } = await addCourseRecodeApi(params)
      if (!success) {
        setItem(0, 'paytips')
        this.$store.dispatch('goPaymentAction', msg)
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    checkRoutePms(to, from, next)
    store.commit('SET_CHECK_ROUTE', true)
  },
  beforeRouteUpdate(to, from, next) {
    checkRoutePms(to, from, next)
    this.$store.commit('SET_CHECK_ROUTE', true)
  },
  beforeDestroy() {
    EventBus.$emit('shortcut-action', 3, false)
    EventBus.$off('ai-ask-change')
    EventBus.$off('guide-change')
    EventBus.$off('shortcut-action')
    this.$store.commit('SET_OPEN_MASK', false)
  }
}
</script>
<style scoped lang="scss">
@keyframes move {
  0% {
    left: 0px;
  }
  100% {
    left: 10px;
  }
}

@keyframes chatBoxMove {
  0% {
    right: 0px;
  }
  100% {
    right: -10px;
  }
}
.vue-splitter-container {
  width: 100%;
  ::v-deep(.splitter-pane-resizer) {
    background: transparent;
  }
  ::v-deep(.splitter-pane-resizer:hover) {
    background: #6651c2;
  }
}
.course-detail {
  width: 100%;
  padding: 80px 0 20px 0;
  box-sizing: content-box;
  .course-header {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #f4f4f4;
    .header-container {
      width: 90vw;
      height: 38px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .course-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .course-content {
      position: relative;
      display: flex;
      width: 90vw;
      height: calc(100vh - 120px);
      .course-menu {
        position: relative;
        display: flex;
        padding-top: 40px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        .icon-mulu {
          position: absolute;
          cursor: pointer;
          left: 10px;
          top: 46px;
          line-height: 35px;
          animation: move .3s ease-in-out;
          // font-size: 22px;
          font-weight: bold;
        }
        .icon-mulu:hover {
          color: #835AFD;
        }
      }
      .course-info {
        // display: flex;
        // flex: 75;
        flex: 1;
        height: 100%;
        padding: 0 10px;
        overflow-y: auto;
      }
      .ai-ask {
        position: relative;
        display: flex;
        padding-top: 30px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }
      .ai-toggle-btn {
        position: absolute;
        width: 68px;
        height: 40px;
        background: #835AFD;
        box-shadow: 0px 1px 4px 0px #D4C6FF;
        border-radius: 100px 0px 0px 100px;
        right: -10px;
        top: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        animation: chatBoxMove .3s ease-in-out;
      }
      .ai-toggle-btn:hover {
        opacity: 0.8;
      }
    }
  }
}
.course-detail.hideNav {
  padding: 10px 0 20px 0;
}
</style>
