<template>
  <div class="course-menu">
    <el-collapse class="hidden-xs-only" v-model="activeNames">
      <el-collapse-item
        v-for="unit in courseData"
        :key="unit.id"
        :name="unit.id">
        <template #title>
          <div class="collapse-header">
            <span v-if="unit.type" class="tag" :class="{'basic': unit.type === '基础', 'progress': unit.type === '进阶'}">{{ unit.type }}</span>
            <span>{{ unit.courseName }}</span>
            <!-- <img v-if="handlerTitleStatus(unit.courseWithLearningStatusList) === null" class="lessons-status lock" src="@/assets/img/lock.png" alt="">
            <img v-if="handlerTitleStatus(unit.courseWithLearningStatusList) === '0'" class="lessons-status unlock" src="@/assets/img/unlock.png" alt="">
            <img v-if="handlerTitleStatus(unit.courseWithLearningStatusList) === '1'" class="lessons-status completed" src="@/assets/img/completed.png" alt=""> -->
            <i v-if="handlerTitleStatus(unit.courseWithLearningStatusList) === null" class="iconfont icon-shangsuo"></i>
            <i v-if="handlerTitleStatus(unit.courseWithLearningStatusList) === '0'" class="iconfont icon-jiesuo"></i>
            <i v-if="handlerTitleStatus(unit.courseWithLearningStatusList) === '1'" class="iconfont icon-tongguo"></i>
          </div>
        </template>
        <div class="menu-content">
          <div
            class="menu-item"
            v-for="lessons in unit.courseWithLearningStatusList"
            :key="`${lessons.courseId}-${lessons.id}`"
            @click="toCourseDetail(lessons)">
            <div class="course-name">
            <i v-if="handlerStatus(lessons) === null" class="iconfont icon-shangsuo"></i>
            <i v-if="handlerStatus(lessons) === '0'" class="iconfont icon-jiesuo"></i>
            <i v-if="handlerStatus(lessons) === '1'" class="iconfont icon-tongguo"></i>
            <span class="tag" v-if="lessons.type === '1'" type="danger">进阶</span>
            <span class="tag" v-if="lessons.type === '2'" type="danger">综合案例</span>
            <span class="tag" v-if="lessons.type === '3'" type="danger">项目实战</span>
              {{ lessons.subdirectoryName  }}
            </div>
            <div class="font-num">
            <i v-if="handlerStatus(lessons) === null" class="iconfont icon-shangsuo"></i>
            <i v-if="handlerStatus(lessons) === '0'" class="iconfont icon-jiesuo"></i>
            <i v-if="handlerStatus(lessons) === '1'" class="iconfont icon-tongguo"></i>
              <span>字数: {{ lessons.wordCount }}</span>
              <span>阅读时长: {{ lessons.readingTime | formatReadingTime }}</span>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-collapse class="hidden-sm-and-up" v-model="activeNames">
      <el-collapse-item
        v-for="unit in courseData"
        :key="unit.id"
        :name="unit.id">
        <template #title>
          <div class="collapse-header">
            <span v-if="unit.type" class="tag" :class="{'basic': unit.type === '基础', 'progress': unit.type === '进阶'}">{{ unit.type }}</span>
            <span>{{ unit.courseName }}</span>
            <!-- <img v-if="handlerTitleStatus(unit.courseWithLearningStatusList) === null" class="lessons-status lock" src="@/assets/img/lock.png" alt="">
            <img v-if="handlerTitleStatus(unit.courseWithLearningStatusList) === '0'" class="lessons-status unlock" src="@/assets/img/unlock.png" alt="">
            <img v-if="handlerTitleStatus(unit.courseWithLearningStatusList) === '1'" class="lessons-status completed" src="@/assets/img/completed.png" alt=""> -->
            <i v-if="handlerTitleStatus(unit.courseWithLearningStatusList) === null" class="iconfont icon-shangsuo"></i>
            <i v-if="handlerTitleStatus(unit.courseWithLearningStatusList) === '0'" class="iconfont icon-jiesuo"></i>
            <i v-if="handlerTitleStatus(unit.courseWithLearningStatusList) === '1'" class="iconfont icon-tongguo"></i>
          </div>
        </template>
        <div class="menu-content">
          <div
            class="menu-item"
            v-for="lessons in unit.courseWithLearningStatusList"
            :key="`${lessons.courseId}-${lessons.id}`"
            @click="toTips">
            <div class="course-name">
              <i v-if="handlerStatus(lessons) === null" class="iconfont icon-shangsuo"></i>
              <i v-if="handlerStatus(lessons) === '0'" class="iconfont icon-jiesuo"></i>
              <i v-if="handlerStatus(lessons) === '1'" class="iconfont icon-tongguo"></i>
              <span class="tag" v-if="lessons.type === '1'" type="danger">进阶</span>
              <span class="tag" v-if="lessons.type === '2'" type="danger">综合案例</span>
              <span class="tag" v-if="lessons.type === '3'" type="danger">项目实战</span>
              <span class="subdirectoryName">{{ lessons.subdirectoryName  }}</span>
            </div>
            <div class="font-num">
            <i v-if="handlerStatus(lessons) === null" class="iconfont icon-shangsuo"></i>
            <i v-if="handlerStatus(lessons) === '0'" class="iconfont icon-jiesuo"></i>
            <i v-if="handlerStatus(lessons) === '1'" class="iconfont icon-tongguo"></i>
              <span>字数: {{ lessons.wordCount }}</span>
              <span>阅读时长: {{ lessons.readingTime | formatReadingTime }}</span>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  name: 'course-menu-all',
  props: {
    courseData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeNames: [1]
    }
  },
  filters: {
    formatReadingTime(value) {
      const min = value.split('.')[0]
      const sec = Math.ceil(`0.${value.split('.')[1]}` * 60)
      return `${min}分${sec}秒`
    }
  },
  methods: {
    ...mapMutations(['SET_COURSE_INFO']),
    toTips() {
      this.$notify({
        title: '温馨提示',
        message: '为了获得最佳学习效果，请您在电脑端浏览器中打开本网页，网页地址：www.studywithgpt.net',
        duration: 0
      })
    },
    toCourseDetail(courseInfo) {
      const { courseId, id, status } = courseInfo
      this.SET_COURSE_INFO(courseInfo)
      const path = `/python/lessons/${id - 1}`
      if (status === null && courseId > 1 && id > 2) return
      if (status === null && id === 2) {
        this.$store.commit('SET_GUIDE_FLAG', true)
      }
      this.$store.commit('SET_CHECK_ROUTE', false)
      this.$router.push(path)
    },
    handlerStatus({ id, status }) {
      if (status === null && id <= 2) return '0'
      return status
    },
    handlerTitleStatus(children) {
      if (children.every(item => item.status === '1')) return '1'
      if (children.some(item => item.status === '0')) return '0'
      if (children.some(item => item.id < 3)) return '0'
      return null
    }
  }
}
</script>
<style scoped lang="scss">
.course-menu {
  flex: 1;
  margin-right: 200px;
}
.el-collapse {
  border-top: none;
  border-bottom: none;
  ::v-deep(.el-collapse-item__header) {
    font-size: 24px;
    background: transparent;
  }
  ::v-deep(.el-collapse-item__wrap) {
    background: transparent;
  }

  .tag {
    padding: 0 10px;
    display: inline-block;
    height: 30px;
    background: #ea3f25;
    border-radius: 15px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin-right: 10px;
  }
  .tag.basic {
    background: #539f38;
  }
  .tag.progress {
    background: #2955e7;
  }

  .lessons-status {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .menu-content {
    width: 100%;
    padding: 30px 50px;
    border: 1px solid rgba(56,56,138,0.12);
    border-radius: 8px;
    box-sizing: border-box;
    .menu-item {
      margin-bottom: 20px;
      .course-name {
        display: flex;
        align-items: center;
        font-size: 22px;
        line-height: 50px;
        cursor: pointer;
        .tag {
          padding: 0 10px;
          display: inline-block;
          height: 30px;
          background: #ea3f25;
          border-radius: 15px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-size: 16px;
          margin-right: 10px;
        }
      }
      .font-num {
        color: #aaa;
        .iconfont {
          opacity: 0;
        }
        span {
          margin-right: 30px;
          font-size: 20px;
        }
      }
    }
    .menu-item:hover {
      color: #835AFD;
    }
  }
  .iconfont {
    font-size: 20px;
  }
  .icon-shangsuo {
    color: #666;
    margin: 0 20px;
  }
  .icon-jiesuo {
    color: #835AFD;
    margin: 0 20px;
  }
  .icon-tongguo {
    color: #65c344;
    margin: 0 17px;
    font-size: 26px;
  }
  .collapse-header {
    font-weight: bolder;
    display: flex;
    align-items: center;
  }
  .collapse-header:hover {
    color: #835AFD;
  }
}
.el-collapse.hidden-sm-and-up {
  border-top: none;
  border-bottom: none;
  ::v-deep(.el-collapse-item__header) {
    font-size: 60px;
  }

  .tag {
    padding: 0 20px;
    display: inline-block;
    height: 70px;
    background: #ea3f25;
    border-radius: 30px;
    line-height: 70px;
    text-align: center;
    color: #fff;
    font-size: 50px;
    margin-right: 20px;
  }
  .tag.basic {
    background: #539f38;
  }
  .tag.progress {
    background: #2955e7;
  }

  .lessons-status {
    width: 34px;
    height: 34px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .menu-content {
    width: 100%;
    padding: 60px 100px;
    border: 1px solid rgba(56,56,138,0.12);
    border-radius: 16px;
    box-sizing: border-box;
    .menu-item {
      margin-bottom: 100px;
      .course-name {
        display: flex;
        align-items: center;
        font-size: 60px;
        line-height: 50px;
        cursor: pointer;
        margin-bottom: 20px;
        .tag {
          padding: 0 20px;
          display: inline-block;
          height: 60px;
          background: #ea3f25;
          border-radius: 30px;
          line-height: 60px;
          text-align: center;
          color: #fff;
          font-size: 40px;
          margin-right: 20px;
        }
        .subdirectoryName {
          flex: 1;
          line-height: 80px;
        }
      }
      .font-num {
        color: #aaa;
        .iconfont {
          opacity: 0;
        }
        span {
          margin-right: 30px;
          font-size: 50px;
        }
      }
    }
    .menu-item:hover {
      color: #835AFD;
    }
  }
  .iconfont {
    font-size: 50px;
  }
  .icon-shangsuo {
    color: #666;
    margin: 0 20px;
  }
  .icon-jiesuo {
    color: #835AFD;
    margin: 0 20px;
  }
  .icon-tongguo {
    color: #65c344;
    margin: 0 17px;
    font-size: 60px;
  }
  .collapse-header {
    font-weight: bolder;
    display: flex;
    align-items: center;
  }
  .collapse-header:hover {
    color: #835AFD;
  }
}
</style>
