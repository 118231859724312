<template>
  <div class="congratulation-box animate__animated animate__bounceInDown">
    <div class="content">
      <div class="top">
        <span>恭喜你通过本节挑战！</span>
        <span @click="jumpToNext">解锁下一节</span>
      </div>
      <div class="bottom">
        <span>你愿意把本教程分享给你的朋友吗？</span>
        <span
          class="copy-btn"
          v-clipboard:copy="inviterUrl"
          v-clipboard:success="copySuccess"
        >
        <el-tooltip class="item" effect="dark" content="通过您的专属链接邀请好友注册后，您和您的好友均可免费获得额外的 10 次GPT-4问答机会。" placement="top-start">
          <span>复制邀请链接</span>
        </el-tooltip>
        </span>
      </div>
    </div>
    <i class="iconfont icon-guanbi" @click="closeMask"></i>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Congratulations',
  computed: {
    ...mapGetters(['inviterUrl'])
  },
  methods: {
    ...mapMutations(['SET_OPEN_MASK', 'SET_MASK_ACTIONS']),
    jumpToNext() {
      this.SET_OPEN_MASK(false)
      const { test } = this.$route.params
      this.$router.push({ path: `/python/lessons/${Number(test) + 1}` })
    },
    copySuccess() {
      this.$message.success('邀请链接已复制!')
    },
    closeMask() {
      this.SET_OPEN_MASK(false)
      this.SET_MASK_ACTIONS('')
    }
  }
}
</script>
<style scoped lang="scss">
.congratulation-box {
  position: relative;
  margin-top: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 400px;
  background: #4CAF4F;
  .icon-guanbi {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;
    color: #fff;
    // font-weight: bold;
    cursor: pointer;
    color: #fff;
  }
  .top, .bottom {
    display: inline-block;
  }
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span:nth-child(1) {
      font-size: 56px;
      font-weight: 600;
      color: #fff;
    }
    span:nth-child(2) {
      width: 150px;
      height: 36px;
      line-height: 36px;
      background: #FFFFFF;
      border-radius: 3px;
      color: #4CAF4F;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .bottom {
    margin-top: 30px;
    font-size: 18px;
    color: #fff;
    .copy-btn {
      position: relative;
      display: inline-block;
      width: 120px;
      height: 30px;
      background: #C3FFC5;
      border-radius: 20px;
      text-align: center;
      line-height: 30px;
      color: #4CAF4F;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      .iconfont {
        color: #fff;
        position: absolute;
        right: -30px;
        top: -30px;
        font-size: 30px;
        font-weight: 600;
      }
    }
  }
}
</style>
