<template>
  <div class="guide-step2">
    <div class="target_container" :style="{ left: `${targetEl.left - 20}px`, top: `${targetTop}px`, width: `${targetWidth}px`, height: `${targetWidth}px` }">
      <div class="target_btn" :style="{ width: `${targetEl.width}px`, height: `${targetEl.height}px` }" @click="runCode">解释</div>
      <div class="tips-container">
        <div class="inner-content">第三步：尝试点击“AI释义”按钮，获取ChatGPT对代码的解释</div>
      </div>
      <div class="right-line"></div>
    </div>
  </div>
</template>
<script>
import { getTargetDOMClint } from '@/utils/dom'
import { mapMutations } from 'vuex'
import { setItem } from '@/utils/storage'
export default {
  name: 'guide-step3',
  data() {
    return {
      targetEl: {}
    }
  },
  computed: {
    targetWidth() {
      return this.targetEl.width + 40
    },
    targetTop() {
      return this.targetEl.top - (this.targetWidth - this.targetEl.height) / 2
    }
  },
  mounted() {
    setTimeout(() => {
      this.targetEl = getTargetDOMClint('.aiInterpretation')
    }, 0)
  },
  methods: {
    ...mapMutations(['SET_OPEN_MASK', 'SET_MASK_ACTIONS']),
    runCode() {
      // const runEle = document.querySelector('.aiInterpretation')
      // runEle.click()
      // this.SET_OPEN_MASK(false)
      this.SET_MASK_ACTIONS('step4')
      setItem('step4', 'guide')
    }
  }
}
</script>
<style scoped lang="scss">
@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.guide-step2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  .target_container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #fff;
    border-radius: 50%;
    .target_btn {
      background: #6a50c9;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      color: #fff;
      animation: scaleAnimation 1s ease-in-out infinite;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .tips-container {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      bottom: 300px;
      width: 840px;
      height: 264px;
      border-radius: 9px 110px 9px 110px;
      border: 2px dashed #FFFFFF;
      .inner-content {
        width: 800px;
        height: 224px;
        background: linear-gradient(139deg, #C6A7FF 0%, #835AFD 100%);
        border-radius: 8px 100px 8px 100px;
        padding: 60px;
        box-sizing: border-box;
        color: #fff;
        font-size: 22px;
        font-weight: 500;
      }
    }
    .right-line {
      position: absolute;
      right: 0;
      bottom: 100px;
      width: 2px;
      height: 200px;
      border-right: 2px dashed #fff;
    }
  }
}
</style>
