import { getItem } from './storage'
import { courseListByCustomerIdApi } from '@/api/course'
import store from '@/store'
export const checkRoutePms = async (to, from, next) => {
  if (!store.state.hasCheckRoute || process.env.NODE_ENV !== 'stage') return next()
  let nextFlag = true
  const userId = getItem('LEARN-PYTHON-TOC-USER') && getItem('LEARN-PYTHON-TOC-USER').userId
  const { data } = await courseListByCustomerIdApi(userId)
  const lessons = to.path.split('lessons/')[1] || to.path.split('test/')[1]
  if (isNaN(lessons)) nextFlag = false
  data.forEach(item => {
    item.courseWithLearningStatusList.forEach(e => {
      if (e.id === Number(lessons) + 1 && e.status === null) {
        nextFlag = false
      }
    })
  })
  if (nextFlag) {
    next()
  } else {
    next('/study')
  }
}
