<template>
  <div v-if="menuShow" :class="{ hide: !menuShow }" class="course-menu-c">
    <div class="menu-header">
      <i class="iconfont icon-shouqi cursor-p" @click="toggleMenu"></i>
      <!-- <span class="font-bold">课程内容</span> -->
    </div>
    <div class="menu-content">
      <div
        v-for="(e, i) in subMenus"
        :key="i"
        class="pl-10 cursor-p"
        :class="{ 'active': e.id === activeId }"
        v-html="e.title"
        @click="jumpContent(e.id)"
      ></div>
      <div v-if="$route.params.lessons !== '0'" :class="{ active: activeId === 'test' }" class="pl-10 font-18 cursor-p" @click="jumpTest">编程练习</div>
    </div>
  </div>
</template>
<script>
import EventBus from './EventBus'
import { cloneDeep } from 'lodash'
export default {
  name: 'course-menu',
  data () {
    return {
      activeNames: [0],
      menuShow: false,
      activeId: '',
      subMenus: []
    }
  },
  created () {
    EventBus.$on('sub-menu-change', subMenus => {
      this.subMenus = this.handlerSubMenu(subMenus)
      const { test } = this.$route.params
      if (test) {
        this.activeId = 'test'
        return
      }
      if (!this.activeId) this.activeId = subMenus[0].id
    })
    EventBus.$on('shortcut-action', (type, isCollapse) => {
      if (type === 2) {
        this.jumpContent('comment')
      }
      if (type === 3 && isCollapse === true) {
        this.toggleMenu()
      }
      if (type === 4) {
        this.jumpContent(this.subMenus[0].id)
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // 'smooth'使滚动平滑，否则默认是'instant'
        })
      }
    })
  },
  methods: {
    // 处理菜单缩进效果
    handlerSubMenu(subMenus) {
      const menus = cloneDeep(subMenus)
      return subMenus.map((item, index) => {
        if (index > 0) {
          for (let i = 0; i < index; i++) {
            const str = menus[i].title.split(' ')[0]
            if (item.title.indexOf(str) === 0) {
              item.title = `&nbsp;&nbsp;&nbsp;&nbsp;${subMenus[i].title.split(str)[0]}${item.title}`
              break
            }
          }
        }
        return {
          ...item
        }
      })
    },
    // 伸缩菜单
    toggleMenu () {
      this.$emit('change', true)
      this.menuShow = false
    },
    // 跳转到指定内容
    async jumpContent(targetId) {
      // debugger
      const { test } = this.$route.params
      if (test) {
        this.$router.push({
          path: `/python/lessons/${test}`
        })
      }
      this.activeId = targetId
      await this.$nextTick()
      // 获取目标元素
      const targetElement = document.getElementById(targetId)
      const parentNode = document.querySelector('.course-info-c')
      // 计算应该滚动的距离
      const rect = targetElement.getBoundingClientRect()
      const parentRect = parentNode.getBoundingClientRect()
      const scrollTop = rect.top - parentRect.top
      // 滚动元素
      parentNode.scrollBy(0, scrollTop)
    },
    // 跳转到练习页面
    jumpTest() {
      this.activeId = 'test'
      const { lessons } = this.$route.params
      if (!lessons) return
      this.$router.push({
        path: `/python/test/${lessons}`
      })
    }
  },
  beforeDestroy() {
    EventBus.$off('sub-menu-change')
    EventBus.$off('shortcut-action')
  }
}
</script>
<style scoped lang="scss">
@keyframes menuHide {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
.course-menu-c {
  width: 100%;
  overflow-y: auto;
  background: #fafafa;
  height: 100%;
  padding: 10px 10px 0 20px;
  .menu-header {
    position: relative;
    display: flex;
    width: 100%;
    height: 35px;
    // justify-content: space-between;
    align-items: center;
    left: 0px;
    font-size: 20px;
    margin-bottom: 20px;
    .font-bold {
      white-space: nowrap;
    }
    .iconfont {
      font-weight: bold;
      font-size: 22px;
      margin-right: 5px;
    }
    .icon-shouqi:hover {
      color: #835AFD;
    }
  }
  .menu-content {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
    .cursor-p {
      // height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
      font-size: 22px;
    }
    .active, .pl-10:hover {
      color: #835AFD;
    }
    .pl-10 {
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 18px;
    }
  }
  .el-collapse {
    width: 100%;
    border-top: none;
    border-bottom: none;
    background-color: #fafafa;
    ::v-deep(.el-collapse-item__wrap) {
      background-color: #fafafa;
      border-bottom: none !important;
    }
    ::v-deep(.el-collapse-item__header) {
      background-color: #fafafa;
      font-size: 16px;
      border-bottom: none;
      height: 50px;
      line-height: 50px;
      white-space: nowrap;
    }

    ::v-deep(.el-collapse-item__content) {
      padding-bottom: 0;
    }
    .pl-10 {
      padding-left: 10px;
      color: #444;
      line-height: 30px;
      white-space: nowrap;
    }

    .pl-10.active {
      color: #835AFD;
    }

    .pl-10:hover {
      color: #835AFD;
    }
  }
  .el-icon-s-fold {
    font-size: 20px;
    margin-right: 10px;
  }
}

.course-menu-c.hide {
  width: 0%;
  animation: menuHide .2s ease-in-out;
  overflow: hidden;
  padding: 0;
  .menu-header {
    opacity: 0;
  }
}
</style>
