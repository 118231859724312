const TOKENKEY = 'LEARN-PYTHON-TOC-USER'

export const setItem = (value, key = TOKENKEY) => {
  let data = value
  if (typeof value === 'object') data = JSON.stringify(value)
  localStorage.setItem(key, data)
}

export const getItem = (key = TOKENKEY) => {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (error) {
    return localStorage.getItem(key)
  }
}

export const removeItem = (key = TOKENKEY) => localStorage.removeItem(key)
