import service from '@/utils/request'

// 添加评论
export const addCommentApi = data => service.post('/api/comment/add', data)

// 获取评论列表
export const commentListApi = courseId => service.get(`/api/comment/list?courseSubdirectoryId=${courseId}`)

// 获取回复评论列表
export const replyListApi = (courseId, commentId) => service.get(`/api/comment/list?courseSubdirectoryId=${courseId}&parentId=${commentId}`)
