import axios from 'axios'
import store from '@/store'
// import router from '@/router'
import { HandlerMessage } from '@/utils/element'

const $message = new HandlerMessage()
// 创建一个axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // 设置请求超时时间，单位毫秒
  timeout: 1000000 // request timeout
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 请求之前拦截，做点什么
    if (store.getters.token) {
      config.headers['X-Letterchain-Token'] = store.getters.token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const { success, code, msg } = response.data
    if (code === '6001' || code === '6002') {
      let msg = '亲爱的用户，您和ChatGPT的精彩对话已到达上限啦！我们诚邀您升级会员，继续享受与ChatGPT一起探索和学习的乐趣'
      if (code === '6002') {
        msg = '亲爱的用户，您的会员已到期！我们诚邀您升级会员，继续享受与ChatGPT一起探索和学习的乐趣'
        store.dispatch('goPaymentAction', msg)
      }
      return { data: msg, code: '6001' }
    }
    if (!success) {
      $message.error(msg)
      if (code === '401') {
        store.commit('SET_USER', {})
        // router.push('/login')
        store.dispatch('getWxLogin')
      }
      return Promise.reject(new Error(msg || '接口请求错误'))
    }
    // 响应之前拦截，对响应结果做点什么
    return response.data
  },
  error => {
    $message.error(error.message)
    return Promise.reject(error)
  }
)

export default service
