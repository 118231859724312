import { Message } from 'element-ui'

export function HandlerMessage() {
  this.success = (message, options) => {
    Message({
      type: 'success',
      message,
      ...options
    })
  }

  this.error = (message, options) => {
    Message({
      type: 'error',
      message,
      ...options
    })
  }

  this.warning = (message, options) => {
    Message({
      type: 'warning',
      message,
      ...options
    })
  }

  this.info = (message, options) => {
    Message({
      type: 'info',
      message,
      ...options
    })
  }
}
